import React from "react";
import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import TestimonialsandBlogSec from "../../Components/Home/TestimonialsandBlogSec";
import WhyUs from "../../Components/Home/WhyUs";
import getHelmetContent from "../../Components/Common/HelmetData";
import { Helmet } from "react-helmet";

const TechnicalTrainer = () => {
  // const pageTitle = "Salary based employment";
  const metaTitle = "Expert in Project OutSourcing and Technology Consulting";
  const helmetContent = getHelmetContent();
  return (

    <>
      <Helmet>
        <meta name="title" content={metaTitle} />
        {/* Remaining meta tags */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Script for Facebook */}
        {helmetContent.script}

        {/* Updated Google Tag Manager */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTNBVH9V');
          `}
        </script>

        {/* Google Analytics Scripts */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { 
            dataLayer.push(arguments); 
          } 
          gtag("js", new Date()); 
          gtag("config", "UA-143016865-1");
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { 
            dataLayer.push(arguments); 
          }
          gtag('js', new Date());
          gtag('config', 'G-BSFPPN5E9D');
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { 
            dataLayer.push(arguments); 
          }
          gtag('js', new Date());
          gtag('config', 'AW-574047969');
          `}
        </script>

        {/* Google Tag Manager noscript fallback */}
        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
      </Helmet>

      <div>
        {/* Header Section */}
        <Header />

        {/* Banner Section */}
        <div >
          <div className="technical-banner-sec flex items-center">
            <div className="container text-left text-white mx-auto">
              <div className="md:w-1/2 mx-4 md:mx-0">
                <h1 className="text-2xl md:text-6xl font-semibold mb-4 ">
                  Technical Trainer
                </h1>
                <Link to="/contact">
                  <button className="btn-style-2 px-6 py-2 ">
                    Get In Touch With Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center py-14  bg-gray-100 fade-in-down px-6">
          <h1 className="text-lg font-normal container mx-auto text-left">
            <br /> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ullamcorper risus at gravida vestibulum. Vivamus fringilla justo vel augue ultrices, nec congue libero dapibus. Aliquam erat volutpat. Nullam aliquet, tortor ut sollicitudin finibus, orci elit aliquam libero, vitae euismod mi quam non dolor. Curabitur et metus sit amet nunc dapibus eleifend vitae non enim. Morbi eget vestibulum quam. In hac habitasse platea dictumst. Phasellus nec aliquam nulla. Quisque auctor aliquet ex, eget efficitur lacus blandit nec. Duis vulputate cursus nunc, id luctus ligula finibus nec. Sed sodales dui et vestibulum cursus. Integer ac dolor a nulla vestibulum ullamcorper vitae vitae nisl. Curabitur ac lacinia elit. Nunc vehicula, quam vel hendrerit ullamcorper, justo libero bibendum mauris, nec dictum felis odio ut quam.
          </h1>
        </div>



        <TestimonialsandBlogSec />

        <ClientLogoCarousel />
        <WhyUs />
        <Footer />

      </div >

    </>
  )
}

export default TechnicalTrainer;