import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import getHelmetContent from "../../Components/Common/HelmetData";
import { Helmet } from "react-helmet";
import WhyUs from "../../Components/Home/WhyUs";
import { motion } from "framer-motion";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Clientele from "./Clientele";
import StickyElements from "./StickyElements";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import GenAIKNIMEBanner from "./GenAIKNIMEBanner";
import SuccessStoryCarousel from "../../Components/Home/SuccessStoryCarousel";
import StudentTestimonials from "./StudentTestimonials";

const GenerativeAIKNIMETraining = () => {
  const webDevelopmentRef = useRef(null);
  const softwareDevelopmentRef = useRef(null);
  const appDevelopmentRef = useRef(null);

  const [showTooltip, setShowTooltip] = useState(false);
  const [screenSize, setScreenSize] = useState(null);

  useEffect(() => {
    const handleClickOutside = () => setShowTooltip(false);
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const downloadPDF = (pdfPaths) => {
    pdfPaths.forEach((pdfPath) => {
      const link = document.createElement("a");
      link.href = pdfPath;
      link.download = pdfPath.split("/").pop();
      link.click();
    });
  };
  useEffect(() => {
    document.title = "Generative AI & KNIME Trianing - Think Quotient";
    AOS.init();

    // // Check if the URL has the anchor link, and scroll to the section if present
    // const hash = window.location.hash.substring(1);
    // if (hash === "web-development") {
    //   scrollToSection(webDevelopmentRef);
    // } else if (hash === "software-development") {
    //   scrollToSection(softwareDevelopmentRef);
    // } else if (hash === "app-development") {
    //   scrollToSection(appDevelopmentRef);
    // }
  }, []);

  useEffect(() => {
    // Get the entire hash string, including the '?'
    const hashString = window.location.hash;

    // Extract the path and query parameters
    const [path, queryString] = hashString.slice(2).split("?"); // Remove the leading '#/' and split at '?'

    // If you need to parse the query parameters, you can use URLSearchParams
    if (queryString) {
      const queryParams = new URLSearchParams(queryString);
      const scrollToSection = queryParams.get("scrollTo");
      console.log("hello " + scrollToSection);
      // Scroll to the specified section
      if (scrollToSection) {
        const targetSection = document.getElementById(scrollToSection);

        if (targetSection) {
          targetSection.scrollIntoView({ behavior: "smooth" });
        }
      }
    }

    // Rest of your useEffect logic
  }, []);

  useEffect(() => {
    // Get the entire hash string, including the '?'
    const hashString = window.location.hash;

    // Extract the path and query parameters
    const [path, queryString] = hashString.slice(2).split("?"); // Remove the leading '#/' and split at '?'

    // If you need to parse the query parameters, you can use URLSearchParams
    if (queryString) {
      const queryParams = new URLSearchParams(queryString);
      const scrollToSection = queryParams.get("scrollTo");

      // Scroll to the specified section
      if (scrollToSection) {
        const targetSection = document.getElementById(scrollToSection);

        if (targetSection) {
          targetSection.scrollIntoView({ behavior: "smooth" });
        }
      }
    }

    // Rest of your useEffect logic
  }, []);

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [activeTab, setActiveTab] = React.useState("tab1");
  const [tabIndex, setTabIndex] = React.useState(0);
  const [tabIndex1, setTabIndex1] = React.useState(0);
  const [tabIndex2, setTabIndex2] = React.useState(0);
  const [tabIndex3, setTabIndex3] = React.useState(0);
  const data = [
    {
      count: "01",
      label: "Python Programming: Build a solid foundation in the most versatile programming language.",
      value: "tab1",
      // image: "/assets/images/Experienced Trainer.jpg",
      // desc: `Learn from seasoned professionals with extensive industry experience. Our trainers deliver practical, real-world insights to ensure your team is equipped with the latest knowledge and skills.`,
    },

    {
      count: "02",
      label: "Data Science: Analyze, visualize, and derive insights from data.",
      value: "tab2",
      // image: "/assets/images/programming-background-collage.jpg",
      // desc: `We work with you to develop a training curriculum aligned with your company’s goals. Each program is tailored to address specific skill gaps and industry demands for maximum impact.`,
    },
    {
      count: "03",
      label: "Machine Learning: Develop predictive models and understand AI fundamentals.",
      // image: "/assets/images/side-view-men-handshaking-agreement-after-meeting.jpg",
      value: "tab3",
      // desc: `Equip your workforce with cutting-edge tools and expertise to drive productivity and innovation. Our training ensures employees are better prepared to meet business challenges.`,
    },
    {
      count: "04",
      label: "Generative AI: Master tools like ChatGPT, DALL·E, and GANs to create groundbreaking applications.",
      // image: "/assets/images/man-giving-bar-graph-presentation-using-high-technology-digital-pen.jpg",
      value: "tab4",
      // desc: `Leverage comprehensive monitoring tools to track employee progress and measure training effectiveness. Gain actionable insights to continuously enhance performance.`,
    },
    {
      count: "05",
      label: "KNIME Analytics Platform: Automate workflows and streamline AI-powered solutions.",
      value: "tab5",
      // image: "/assets/images/Capstone Project.jpg",
      // desc: `Foster practical learning through hands-on projects that replicate real-world scenarios. Employees apply their skills to solve problems, building confidence and competence.`,
    },
   
  ];

  const data1 = [
    {
      count: "01",
      label: "Industry-Relevant Curriculum: Designed by experts to meet current job market demands.",
      value: "tab1",
      // image: "/assets/images/Experienced Trainer.jpg",
      // desc: `Learn from seasoned professionals with extensive industry experience. Our trainers deliver practical, real-world insights to ensure your team is equipped with the latest knowledge and skills.`,
    },

    {
      count: "02",
      label: "Hands-On Learning: Work on real-world projects and a capstone project to showcase your skills.",
      value: "tab2",
      // image: "/assets/images/programming-background-collage.jpg",
      // desc: `We work with you to develop a training curriculum aligned with your company’s goals. Each program is tailored to address specific skill gaps and industry demands for maximum impact.`,
    },
    {
      count: "03",
      label: "Globally Recognized Certification: Earn credentials that set you apart.",
      // image: "/assets/images/side-view-men-handshaking-agreement-after-meeting.jpg",
      value: "tab3",
      // desc: `Equip your workforce with cutting-edge tools and expertise to drive productivity and innovation. Our training ensures employees are better prepared to meet business challenges.`,
    },
    {
      count: "04",
      label: "Supportive Community: Learn with peers and receive guidance from industry professionals.",
      // image: "/assets/images/man-giving-bar-graph-presentation-using-high-technology-digital-pen.jpg",
      value: "tab4",
      // desc: `Leverage comprehensive monitoring tools to track employee progress and measure training effectiveness. Gain actionable insights to continuously enhance performance.`,
    },
   
  ];

  const data2 = [
    {
      count: "01",
      label: "Aspiring data scientists, AI enthusiasts, and machine learning engineers.",
      value: "tab1",
      // image: "/assets/images/Experienced Trainer.jpg",
      // desc: `Learn from seasoned professionals with extensive industry experience. Our trainers deliver practical, real-world insights to ensure your team is equipped with the latest knowledge and skills.`,
    },

    {
      count: "02",
      label: "Professionals looking to upskill and advance their careers.",
      value: "tab2",
      // image: "/assets/images/programming-background-collage.jpg",
      // desc: `We work with you to develop a training curriculum aligned with your company’s goals. Each program is tailored to address specific skill gaps and industry demands for maximum impact.`,
    },
    {
      count: "03",
      label: "Entrepreneurs eager to leverage AI for business innovation.",
      // image: "/assets/images/side-view-men-handshaking-agreement-after-meeting.jpg",
      value: "tab3",
      // desc: `Equip your workforce with cutting-edge tools and expertise to drive productivity and innovation. Our training ensures employees are better prepared to meet business challenges.`,
    },
       
  ];

  const data3 = [
    {
      count: "01",
      label: "Confidence in Python programming and data handling.",
      value: "tab1",
      // image: "/assets/images/Experienced Trainer.jpg",
      // desc: `Learn from seasoned professionals with extensive industry experience. Our trainers deliver practical, real-world insights to ensure your team is equipped with the latest knowledge and skills.`,
    },

    {
      count: "02",
      label: "Expertise in AI-driven technologies and applications.",
      value: "tab2",
      // image: "/assets/images/programming-background-collage.jpg",
      // desc: `We work with you to develop a training curriculum aligned with your company’s goals. Each program is tailored to address specific skill gaps and industry demands for maximum impact.`,
    },
    {
      count: "03",
      label: "Practical knowledge to solve complex problems with automation and AI.",
      // image: "/assets/images/side-view-men-handshaking-agreement-after-meeting.jpg",
      value: "tab3",
      // desc: `Equip your workforce with cutting-edge tools and expertise to drive productivity and innovation. Our training ensures employees are better prepared to meet business challenges.`,
    },
    {
      count: "04",
      label: "A portfolio of projects to impress recruiters and clients.",
      // image: "/assets/images/man-giving-bar-graph-presentation-using-high-technology-digital-pen.jpg",
      value: "tab4",
      // desc: `Leverage comprehensive monitoring tools to track employee progress and measure training effectiveness. Gain actionable insights to continuously enhance performance.`,
    },
   
  ];


  const metaTitle = "Generative AI & KNIME Trianing  - Think Quotient";
  const helmetContent = getHelmetContent();

  const fadeVariants = {
    initial: { opacity: 0, y: 10 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: -10, transition: { duration: 0.5 } },
  };


  const [activeTestimonial, setActiveTestimonial] = useState(0); // Track active testimonial slide index

  const testimonialsRef = useRef(null);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,           // Enable autoplay
    autoplaySpeed: 3500,
    beforeChange: (_, next) => setActiveTestimonial(next), // Update active testimonial on change
  };

  const goToTestimonialSlide = (index) => {
    testimonialsRef.current.slickGoTo(index);
  };


  const scrollRef = useRef(null); // Reference to the content container


  const handleTabClick = (index) => {
    setTabIndex(index);
  
  };

  const [isTabVisible, setIsTabVisible] = useState(false);
  const tabRef = useRef(null); // Create a ref for the tab content


  const handleTabClick1 = (index) => {
    setTabIndex1(index);
   
  };

  const handleTabClick2 = (index) => {
    setTabIndex2(index);
   
  };
  const handleTabClick3 = (index) => {
    setTabIndex3(index);
   
  };
  return (

    <>
      <Helmet>
        {/* Basic Meta Tags */}
        <title>{"Generative AI & KNIME Trianing - Think Quotient"}</title>
        <meta
          name="description"
          content={
            "Empower your team with Think Quotient's Generative AI & KNIME Training Program. Customized courses in web development, software development, app development, and cutting-edge technologies to boost productivity and skills.,corporate training, IT training for companies, custom training programs, upskilling teams, web development training, software development, app development, artificial intelligence, machine learning, data science, python, full stack development, generative AI,  KNIME, data science, think quotient"
          }
        />
        <meta
          name="keywords"
          content={
            "generative AI & KNIME training, IT training for generative AI & KNIME, custom training programs, upskilling teams, web development training, software development, app development, artificial intelligence, machine learning, data science, python, full stack development, generative AI,  KNIME, data science, think quotient"
          }
        />

        {/* Dynamic Meta Tags */}
        {helmetContent?.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Social Media Sharing */}
        <meta property="og:title" content={metaTitle || "Generative AI & KNIME Trianing  - Think Quotient"} />
        <meta
          property="og:description"
          content="Think Quotient's Generative AI & KNIME Training Program helps people upskill in Generative AI, KNIME, Python and Data Science, ensuring they stay ahead in the tech-driven world."
        />
        <meta
          property="og:image"
          content="https://www.thinkquotient.com/assets/images/corporate-training-banner.png"
        />
        <meta property="og:url" content="https://www.thinkquotient.com/training/generativeAI-KNIMETraining" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTitle || "Generative AI & KNIME Trianing- Think Quotient"} />
        <meta
          name="twitter:description"
          content="Think Quotient's Generative AI & KNIME Training Program helps people upskill in Generative AI, KNIME, Python and Data Science, ensuring they stay ahead in the tech-driven world."
        />
        <meta
          name="twitter:image"
          content="https://www.thinkquotient.com/assets/images/corporate-training-banner.png"
        />

        {/* Robots and Author */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Think Quotient" />

        {/* Updated Google Tag Manager */}
        <script>
          {`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-KTNBVH9V');
    `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "UA-143016865-1");
    `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-BSFPPN5E9D');
    `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'AW-574047969');
    `}
        </script>

        {/* Google Tag Manager Noscript */}
        <noscript>
          {`
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V"
        height="0"
        width="0"
        style="display:none;visibility:hidden"
      ></iframe>
    `}
        </noscript>
      </Helmet>



      <div className="overflow-hidden">
        {/* Header Section */}
        <Header />
        <GenAIKNIMEBanner />

        <div className="flex flex-col items-center py-14  bg-gray-100 fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="font-bold text-2xl ">
              Unlock the Power of AI & Data Science at ThinkQuotient{" "}
              </p>
            </div>
            <br /> In today’s fast-paced digital world, staying ahead means mastering cutting-edge technologies. Our Comprehensive AI and Data Science Course is designed to equip you with the skills and knowledge to thrive in the evolving landscape of Artificial Intelligence and Machine Learning.
          </h1>
        </div>

        <div className="flex flex-col items-center py-14  fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="font-bold text-2xl ">
              Generative AI & KNIME Training{" "}
              </p>
            </div>
          </h1>
        </div>

        <div className="m-0 flex justify-center items-center sm:p-2 sm:mx-0 mx-0">
          <div
            className="flex flex-col justify-center items-center w-full"
            data-aos="fade-left"
            data-aos-duration="1000"
            id="web-development"
          >
            {/* Top Section: Image */}
            <div className="flex-1 w-full max-w-md sm:max-w-lg lg:max-w-xl">
              <img
                src="/assets/images/datascienceGenAIKNIME.jpeg"
                className="object-contain w-full h-auto"
                alt="Training Approach"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center pt-14   fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="text-3xl sm:text-5xl xl:text-3xl font-bold ">
              What Our Course Offer{" "}
              </p>
            </div>
          </h1>
        </div>

        <div

          className="container  mx-auto py-14 px-4 hidden  lg:block"
          ref={tabRef} >
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div w-full"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => setTabIndex(index)} // Update tabIndex on click
                  className={`${tabIndex === index
                      ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                      : "text-gray-500 py-2 px-4"
                    } transition duration-200 ease-in-out transform hover:text-blue-500 hover:scale-105`}
                  style={{
                    cursor: "pointer",
                    color: tabIndex === index ? "blue" : "inherit", // Inline style for color
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl  mb-6 ${tabIndex === index ? "text-blue-500" : "text-gray-500"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>

            {/* <div className="tab-content md:w-1/2 p-4" id="slide-sec">
              {data[tabIndex] && (
                <motion.div
                  key={tabIndex} // Re-run animation on tabIndex change
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={fadeVariants}
                  className="relative w-full"
                >
                  <div className="relative w-full md:w-[20rem] h-64 mb-6 md:mb-0">
                    <div className="w-full h-full bg-gray-200 overflow-hidden rounded-lg">
                      <motion.img
                        src={data[tabIndex].image}
                        alt={`${data[tabIndex].label} image`}
                        className="object-cover w-full h-full rounded-lg"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.5 } }}
                      />
                    </div>

                    <motion.div
                      // className="bg-white shadow-lg p-8 rounded-lg w-full md:w-[calc(100%--3rem)] absolute top-[7rem] left-[12rem] md:h-auto overflow-auto"
                      className="custom-card"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { duration: 0.5 } }}
                    >
                      <p className="text-md md:text-lg">
                        {data[tabIndex].desc}
                      </p>
                    </motion.div>
                  </div>
                </motion.div>
              )}
            </div>*/}
          </Tabs> 
        </div>



        <div
          ref={tabRef}
          className="container mx-auto py-10 px-4 md:block lg:hidden">
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div w-full"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => handleTabClick(index)}// Update tabIndex on click
                  className={`${tabIndex === index
                      ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                      : "text-gray-500 py-2 px-4"
                    }`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl mb-6 ${tabIndex === index ? "text-blue-500" : "text-gray-500"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>

            {/* <div ref={scrollRef} className="tab-content md:w-1/2 p-4" id="slide-sec" style={{ scrollMarginTop: "8.5rem" }}>
              {data[tabIndex] && (
                <div className="relative w-full">
                
                  <div className="flex flex-col md:flex-col items-center md:items-start">
                    
                    <div className="relative w-full md:w-[20rem] h-64 mb-6 md:mb-0">
                      <div className="w-full h-full bg-gray-200 overflow-hidden rounded-lg">
                        <img
                          src={data[tabIndex].image}
                          alt={`${data[tabIndex].label} image`}
                          className="object-cover w-full h-full rounded-lg"
                        />
                      </div>
                    </div>

                 
                    <div className="bg-white shadow-lg p-4 rounded-lg w-full md:w-[calc(100%-1rem)] md:ml-0 mt-4 md:mt-0">
                      <p className="text-md md:text-lg">
                        {data[tabIndex].desc}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div> */}
          </Tabs>
        </div>

      {/* Why choose us section */}
        <div className="flex flex-col items-center pt-14   fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="text-3xl sm:text-5xl xl:text-3xl font-bold ">
              Why Choose Us{" "}
              </p>
            </div>
          </h1>
        </div>

        <div

          className="container  mx-auto py-14 px-4 hidden  lg:block"
        >
          <Tabs
            selectedIndex={tabIndex1}
            onSelect={(index) => setTabIndex1(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div w-full"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data1.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => setTabIndex1(index)} // Update tabIndex on click
                  className={`${tabIndex1 === index
                      ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                      : "text-gray-500 py-2 px-4"
                    } transition duration-200 ease-in-out transform hover:text-blue-500 hover:scale-105`}
                  style={{
                    cursor: "pointer",
                    color: tabIndex1 === index ? "blue" : "inherit", // Inline style for color
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl  mb-6 ${tabIndex1 === index ? "text-blue-500" : "text-gray-500"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>
          </Tabs> 
        </div>



        <div
          className="container mx-auto py-10 px-4 md:block lg:hidden">
          <Tabs
            selectedIndex={tabIndex1}
            onSelect={(index) => setTabIndex1(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div w-full"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data1.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => handleTabClick1(index)}// Update tabIndex on click
                  className={`${tabIndex1 === index
                      ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                      : "text-gray-500 py-2 px-4"
                    }`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl mb-6 ${tabIndex1 === index ? "text-blue-500" : "text-gray-500"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>

          </Tabs>
        </div>


           {/* Who is this Course For? section */}
           <div className="flex flex-col items-center pt-14   fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="text-3xl sm:text-5xl xl:text-3xl font-bold ">
              Who is this Course For?{" "}
              </p>
            </div>
          </h1>
        </div>

        <div

          className="container  mx-auto py-14 px-4 hidden  lg:block"
        >
          <Tabs
            selectedIndex={tabIndex2}
            onSelect={(index) => setTabIndex2(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div w-full"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data2.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => setTabIndex2(index)} // Update tabIndex on click
                  className={`${tabIndex2 === index
                      ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                      : "text-gray-500 py-2 px-4"
                    } transition duration-200 ease-in-out transform hover:text-blue-500 hover:scale-105`}
                  style={{
                    cursor: "pointer",
                    color: tabIndex2 === index ? "blue" : "inherit", // Inline style for color
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl  mb-6 ${tabIndex2 === index ? "text-blue-500" : "text-gray-500"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>
          </Tabs> 
        </div>



        <div
          className="container mx-auto py-10 px-4 md:block lg:hidden">
          <Tabs
            selectedIndex={tabIndex2}
            onSelect={(index) => setTabIndex2(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div w-full"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data2.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => handleTabClick2(index)}// Update tabIndex on click
                  className={`${tabIndex2 === index
                      ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                      : "text-gray-500 py-2 px-4"
                    }`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl mb-6 ${tabIndex2 === index ? "text-blue-500" : "text-gray-500"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>

          </Tabs>
        </div>

{/* What Will You Achieve? section */}
<div className="flex flex-col items-center pt-14   fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="text-3xl sm:text-5xl xl:text-3xl font-bold ">
              What Will You Achieve?{" "}
              </p>
            </div>
          </h1>
        </div>

        <div

          className="container  mx-auto py-14 px-4 hidden  lg:block"
        >
          <Tabs
            selectedIndex={tabIndex3}
            onSelect={(index) => setTabIndex3(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div w-full"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data3.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => setTabIndex3(index)} // Update tabIndex on click
                  className={`${tabIndex3 === index
                      ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                      : "text-gray-500 py-2 px-4"
                    } transition duration-200 ease-in-out transform hover:text-blue-500 hover:scale-105`}
                  style={{
                    cursor: "pointer",
                    color: tabIndex3 === index ? "blue" : "inherit", // Inline style for color
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl  mb-6 ${tabIndex3 === index ? "text-blue-500" : "text-gray-500"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>
          </Tabs> 
        </div>



        <div
          className="container mx-auto py-10 px-4 md:block lg:hidden">
          <Tabs
            selectedIndex={tabIndex3}
            onSelect={(index) => setTabIndex3(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div w-full"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data3.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => handleTabClick3(index)}// Update tabIndex on click
                  className={`${tabIndex3 === index
                      ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                      : "text-gray-500 py-2 px-4"
                    }`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl mb-6 ${tabIndex3 === index ? "text-blue-500" : "text-gray-500"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>

          </Tabs>
        </div>


        <Clientele />
        <SuccessStoryCarousel />
        <StudentTestimonials />
        <StickyElements />
        <Footer />
      </div>
    </>
  );
};

export default GenerativeAIKNIMETraining;


