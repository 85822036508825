import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const Clientele = () => {
  const clientLogos = [];

  // Update the paths for logos from '/assets/client_logos/cl (1)' to '/assets/client_logos/cl (31)'
  for (let i = 1; i < 41; i++) {
    const imagePath = `/assets/client_logos/cl (${i}).jpg`;
    clientLogos.push(imagePath);
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
    prevArrow: (
      <button type="button" className="slick-arrow slick-prev">
        Left &lt;
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-arrow slick-next">
        Right &gt;
      </button>
    ),
  };

  return (
    <>
      <div className="client-logo-carousel pt-20 2xl:w-10/12 mx-auto relative">
        <h1 className="text-3xl text-center font-bold mb-10">Clientele</h1>
        <Slider {...settings}>
          {clientLogos.map((logo, index) => (
            <div key={index} className="client-logo h-full flex items-center justify-center">
              <img src={logo} alt={`Client Logo ${index + 1}`} className='object-cover my-auto h-full' />
            </div>
          ))}
        </Slider>
      </div>

  
    </>
  );
};

export default Clientele;
