import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from "../Components/Layout/Header";
import Footer from '../Components/Layout/Footer';

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = 'Privacy Policy - Think Quotient';
    AOS.init();
  }, []);

  return (
    <>
      <div className="overflow-hidden">
        {/* Header Section */}
        <Header />

        {/* Privacy Policy Content Section */}
        <div className="bg-gradient-to-r from-gray-100 to-white py-12 md:py-16" data-aos="fade-up" data-aos-duration="1000">
          <div className="container mx-auto px-6 md:px-12 lg:px-20">
            <div className="bg-white shadow-lg rounded-lg p-6 md:p-12">
              <h1 className="text-4xl font-bold text-blue-800 text-center mb-8">
                Privacy Policy
              </h1>
              <p className="text-lg text-gray-700 leading-relaxed mb-8 text-center">
                At ThinkQuotient, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, share, and safeguard your personal information when you interact with our website and services.
              </p>

              <div className="space-y-10">
                {/* Point 1: Information We Collect */}
                <div>
                  <h2 className="text-2xl font-semibold text-blue-700 mb-4">
                    1. Information We Collect
                  </h2>
                  <p className="text-gray-600 mb-4">
                    We collect the following types of information to provide our services effectively:
                  </p>
                  <ul className="list-decimal pl-5 text-gray-600 space-y-2">
                    <li><strong>Personal Details:</strong> This includes your name, email address, phone number, and other details provided by you.</li>
                    <li><strong>Website Usage Data:</strong> Information about your interactions with our website, such as IP address, browser type, pages visited, and the duration of visits.</li>
                  </ul>
                </div>

                {/* Point 2: How We Use Your Information */}
                <div>
                  <h2 className="text-2xl font-semibold text-blue-700 mb-4">
                    2. How We Use Your Information
                  </h2>
                  <p className="text-gray-600">
                    The information we collect is used to:
                  </p>
                  <ul className="list-decimal pl-5 text-gray-600 space-y-2">
                    <li>Respond to your inquiries and provide customer support.</li>
                    <li>Send updates about our training programs, courses, and new offerings.</li>
                    <li>Enhance the functionality and usability of our website.</li>
                    <li>Ensure compliance with legal obligations.</li>
                  </ul>
                </div>

                {/* Point 3: Consent */}
                <div>
                  <h2 className="text-2xl font-semibold text-blue-700 mb-4">
                    3. Consent
                  </h2>
                  <p className="text-gray-600">
                    By using our website, you consent to the collection and use of your personal information as outlined in this Privacy Policy. You have the right to withdraw your consent at any time by contacting us.
                  </p>
                </div>

                {/* Point 4: Security Measures */}
                <div>
                  <h2 className="text-2xl font-semibold text-blue-700 mb-4">
                    4. Security Measures
                  </h2>
                  <p className="text-gray-600">
                    We implement industry-standard security measures, such as encryption and firewalls, to protect your data. While we strive to protect your information, no method of transmission over the Internet or electronic storage is 100% secure.
                  </p>
                </div>

                {/* Point 5: Data Retention Policy */}
                <div>
                  <h2 className="text-2xl font-semibold text-blue-700 mb-4">
                    5. Data Retention Policy
                  </h2>
                  <p className="text-gray-600">
                    We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by applicable laws. Once the data is no longer needed, we securely delete or anonymize it.
                  </p>
                </div>

                {/* Point 6: Cookies and Tracking Technologies */}
                <div>
                  <h2 className="text-2xl font-semibold text-blue-700 mb-4">
                    6. Cookies and Tracking Technologies
                  </h2>
                  <p className="text-gray-600">
                    We use cookies to improve your browsing experience, analyze website traffic, and personalize content. You can manage or disable cookies in your browser settings; however, some features of our website may be impacted.
                  </p>
                </div>

                {/* Point 7: Sharing Your Information */}
                <div>
                  <h2 className="text-2xl font-semibold text-blue-700 mb-4">
                    7. Sharing Your Information
                  </h2>
                  <p className="text-gray-600">
                    Your information is not sold or rented. We may share it with:
                  </p>
                  <ul className="list-decimal pl-5 text-gray-600 space-y-2">
                    <li><strong>Service Providers:</strong> Trusted third-party vendors who assist us with operations, such as email services and payment processing.</li>
                    <li><strong>Legal Authorities:</strong> When required to comply with legal obligations or protect our rights.</li>
                  </ul>
                </div>

                {/* Point 8: International Data Transfers */}
                <div>
                  <h2 className="text-2xl font-semibold text-blue-700 mb-4">
                    8. International Data Transfers
                  </h2>
                  <p className="text-gray-600">
                    If you access our website outside of India, please note that your information may be transferred across borders. We ensure such transfers comply with data protection regulations.
                  </p>
                </div>

                {/* Point 9: Children's Privacy */}
                <div>
                  <h2 className="text-2xl font-semibold text-blue-700 mb-4">
                    9. Children's Privacy
                  </h2>
                  <p className="text-gray-600">
                    Our services are not directed at children under 13 years of age. We do not knowingly collect personal information from children. If you believe a child has provided us with personal information, please contact us to have it removed.
                  </p>
                </div>

                {/* Point 10: Updates to This Privacy Policy */}
                <div>
                  <h2 className="text-2xl font-semibold text-blue-700 mb-4">
                    10. Updates to This Privacy Policy
                  </h2>
                  <p className="text-gray-600">
                    We may revise this Privacy Policy periodically to reflect changes in our practices or for legal reasons. The updated policy will be posted on this page with the effective date.
                  </p>
                </div>

                {/* Contact Section */}
                <div className="bg-gradient-to-r from-blue-50 to-white p-6 rounded-lg shadow-md">
                  <h2 className="text-2xl font-semibold text-blue-700 mb-4">
                    Contact Us
                  </h2>
                  <p className="text-gray-600 mb-4">
                    If you have any questions or concerns regarding this Privacy Policy, you can reach us at:
                  </p>
                  <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-6">
                    <p className="text-gray-600">
                      <strong>Call:</strong> <a href="tel:+917030055128" className="text-blue-600 hover:underline">+91-7030055128</a>
                    </p>
                    <span className="hidden md:inline-block text-gray-400">|</span>
                    <p className="text-gray-600">
                      <strong>Email:</strong> <a href="mailto:support@thinkquotient.com" className="text-blue-600 hover:underline">support@thinkquotient.com</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer Section */}
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;
