import React from "react";
import "./newPattern.css";

const NewPattern = () => {
  return (
    <div className="new-pattern absolute !z-[1] left-10 md:mt-10 mt-[200px] hidden md:block">
      <svg
        className="animated-pattern"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        width="600"
        height="600"
      >
        <defs>
          <radialGradient id="grad1" cx="50%" cy="50%" r="50%">
            <stop offset="0%" style={{ stopColor: "#e76434", stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: "#ffda77", stopOpacity: 0.5 }} />
          </radialGradient>
        </defs>
        <circle
          cx="250"
          cy="250"
          r="200"
          fill="none"
          stroke="url(#grad1)"
          strokeWidth="5"
          className="circle-animation"
        />
        <path
          d="M 100 300 Q 250 50, 400 300 T 700 300"
          fill="none"
          stroke="#00BFFF"
          strokeWidth="3"
          className="wave-animation"
        />
        <circle
          cx="250"
          cy="250"
          r="20"
          fill="#e76434"
          className="pulse-animation"
        />
      </svg>
    </div>
  );
};

export default NewPattern;
