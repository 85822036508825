import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StudentTestimonials = () => {
  // Testimonials data
  const testimonialsData = [
    {
      id: 1,
      icon: "path/to/icon-1.png",
      // heading: 'Art Freedom and Creativity',
      text: "I had an excellent experience at ThinkQuotient. The instructors were highly knowledgeable and provided in-depth training on various technologies, including Java, SQL, Angular, and .NET. What truly set this training apart was the extensive hands-on practice they offered, which really solidified my understanding of the concepts. I highly recommend ThinkQuotient to anyone looking to enhance their skills and advance their career in software development!",
      name: "Hrishikesh Kokate",
      image: "",
    },

    {
      id: 2,
      icon: "path/to/icon-3.png",
      // heading: 'Testimonial Heading 3',
      text: "I have join thinkquotient in may 2024 and its good experience at ThinkQuotient! The teachers are supportive and focus on practical skills. and they even helped me with soft skills, which are good.",
      name: "Yogesh Chavan",
      image: "",
    },
    {
      id: 3,
      icon: "path/to/icon-4.png",
      // heading: "Testimonial Heading 4",
      text: "I joined Think Quotient in January for my training, and it was an excellent experience from day one. The teachers helped me settle into the environment and made sure I felt comfortable. A few months later, they prepared me to attend job interviews, arranging mock interviews and monthly evaluations to ensure I was ready. I am deeply grateful for the training I received at Think Quotient and would highly recommend it to others looking to start their careers.",
      name: "Sanket Nigade",
      image: "",
    },
    {
      id: 4,
      icon: "path/to/icon-2.png",
      // heading: 'Testimonial Heading 2',
      text: "Think Quotient Software transformed my IT career! Their trainers provided a strong foundation in both technical skills and interview preparation. Monthly mock tests and weekly assessments kept me sharp. The supportive mentors were invaluable for guidance. Huge thanks to the placement team for helping me land a fantastic job!",
      name: "Anuj Jagtap",
      image: "",
    },
    {
      id: 5,
      icon: "path/to/icon-2.png",
      // heading: 'Testimonial Heading 2',
      text: "I recently completed the developer program at ThinkQuotient, and I’m thrilled to share my positive experience. Their training covers both technical and soft skills, ensuring a well-rounded preparation for the job market. The placement team was instrumental in securing a job for me. Weekly mock tests and daily assessments were crucial in honing my programming skills. Thanks to ThinkQuotient, I am now employed with a IT-based company.",
      name: "Prasad Deshmukh",
      image: "",
    },
    {
    id: 6,
      icon: "path/to/icon-1.png",
      // heading: 'Art Freedom and Creativity',
      text: "Think Quotient Software is a good platform to start your career in IT industry. They provide trainer for technical skills and soft skills. Mentors has been very supportive and co-operative. Placement team help me to secured job. Weekly mocks and daily assessment also helps me to improve my programming skills. I got placed in pune based company with package of 4.2LPA. Thanks to Think Quotient Team",
      name: "Sneha Mahale",
      image: "",
    },

    {
      id: 7,
      icon: "path/to/icon-3.png",
      // heading: 'Testimonial Heading 3',
      text: "In ThinkQuotient all trainers are very supportive and calm.ThinkQuotient is a great place to kickstart your IT career! They offer top-notch training that equips you with the skills needed for success. They provide excellent training and good placement team that's why I can say Think Quotient is the best place to start your career in IT industry..",
      name: "Sudarshan Narhe",
      image: "",
    },
    {
      id: 8,
      icon: "path/to/icon-4.png",
      // heading: "Testimonial Heading 4",
      text: "My experience with Think Quotient has been exceptional. From the moment I enrolled in their full stack developer training program to landing a job with their assistance, they've exceeded my expectations every step of the way. They provided guidance on resume building, interview preparation , ultimately helping me secure a job in a reputable company in the industry. I wholeheartedly recommend Think Quotient.",
      name: "Suraj Rokade",
      image: "",
    },
    {
      id: 9,
      icon: "path/to/icon-2.png",
      // heading: 'Testimonial Heading 2',
      text: "ThinkQuotient is a great place to kickstart your IT career! They offer top-notch training that equips you with the skills needed for success. Plus, they provide placement drives, which is a fantastic opportunity to land a job. The training program also focuses on mock interviews and enhancing soft skills, giving you an edge in cracking interviews. Interacting with the Placement team was a great experience.",
      name: "Madhura Radkar",
      image: "",
    },
    {
      id: 10,
      icon: "path/to/icon-2.png",
      // heading: 'Testimonial Heading 2',
      text: "My journey at ThinkQuotient Pvt Ltd, from intern to professional, has been truly fulfilling. The collaborative culture and supportive leadership have allowed me to dive into exciting projects, including getting to work with KNIME technology. Grateful for the growth opportunities and excited for the next chapter!",
      name: "Yogesh Nawale",
      image: "",
    },
    {
    id: 11,
    icon: "path/to/icon-1.png",
    // heading: 'Art Freedom and Creativity',
    text: "Fantastic experience at ThinkQuotient! The teachers are supportive and focus on practical skills. and they even helped me with soft skills, which are crucial.",
    name: "Abhijeet Dagade",
    image: "",
  },

  {
    id: 12,
    icon: "path/to/icon-3.png",
    // heading: 'Testimonial Heading 3',
    text: "I recently completed my training at ThinkQuotient software Pvt Ltd and I must say my experience was great and positive .Placement team and trainers are very supportive. I get placed in intelliment technologies.",
    name: "Dipti Bhalerao",
    image: "",
  },
  {
    id: 13,
    icon: "path/to/icon-4.png",
    // heading: "Testimonial Heading 4",
    text: "I am writing to express my sincere gratitude for the invaluable support and guidance you provided during my time at ThinkQuotient software pvt ltd. Your dedication to helping students like myself navigate the transition from academia to the professional world has made a profound impact on my career journey.Thank for your support throughout my placement journey.",
    name: "Sagar Kawade",
    image: "",
  },
  {
    id: 14,
    icon: "path/to/icon-2.png",
    // heading: 'Testimonial Heading 2',
    text: "I got good experience learning about java, MySQL, Springboot, angular. All the teachers helped and guided me a lot to get the job I am a passout of 2018 but I got a package of Rs 3.5 lakh in ECS technology thank you Think Quotient",
    name: "Jitendra Desale",
    image: "",
  },
  {
    id: 15,
    icon: "path/to/icon-2.png",
    // heading: 'Testimonial Heading 2',
    text: "ThinkQuotient Software Private Limited is a fantastic place for those seeking quality training, supportive mentors, and a pathway to a successful career. I highly recommend their services to anyone looking to enhance their skills and secure a bright future. Thank you, ThinkQuotient, for your outstanding support!",
    name: "Abhishek Ghodase",
    image: "",
  },
  {
    id: 16,
    icon: "path/to/icon-1.png",
    // heading: 'Art Freedom and Creativity',
    text: "Think Quotient is the best platform to learn and grow. The staff and team of ThinkQuotient are helping in nature and friendly. Weekly mocks and assessment helped me alot to achieve my goal also soft skill session helped interaction between students and teacher is really good.I got good opportunity from ThinkQuotient in Pune. Thanks to all mam and sir. Thank you for your guidance, support and efforts.",
    name: "Aniket Lokare",
    image: "",
  },

  {
    id: 17,
    icon: "path/to/icon-3.png",
    // heading: 'Testimonial Heading 3',
    text: "At ThinkQuotient, I learned a lot. The teachers were really helpful and taught practical skills. Thanks to them, I landed a job in a big company in Mumbai with a salary of 3.5 LPA. They also organized important soft skill sessions to enhance my corporate abilities. I am thankful for everything they did for me. If you're looking for a place to start your career, I highly recommend ThinkQuotient.",
    name: "Anuja Dange",
    image: "",
  },
  {
    id: 18,
    icon: "path/to/icon-4.png",
    // heading: "Testimonial Heading 4",
    text: "Think Quotient is the best platform to learn and grow. The staff and team of ThinkQuotient are helping in nature and friendly. Weekly mocks and assessment helped me alot to achieve my goal also soft skill session helped interaction between students and teacher is really good.I got good opportunity from ThinkQuotient in Mumbai with 3.5 LPA. Thanks to all mam and sir. Thank you for your guidance, support and efforts.",
    name: "Shivani Hatalage",
    image: "",
  },
  {
    id: 19,
    icon: "path/to/icon-2.png",
    // heading: 'Testimonial Heading 2',
    text: "Fantastic experience at ThinkQuotient! The teachers are supportive and focus on practical skills. I landed a job quickly after completing my course, and they even helped me with soft skills, which are crucial. Thrilled to have joined here! Grateful for the opportunity to work with a Mumbai-based company with a package of 3.5 LPA. Thank you for all the guidance and support!",
    name: "Suraj Yelmate",
    image: "",
  },
  {
    id: 20,
    icon: "path/to/icon-2.png",
    // heading: 'Testimonial Heading 2',
    text: "I recently had the opportunity to engage with ThinkQuotient Software Pvt. Ltd. for training and placement services, and I must say, it has been an exceptional experience. This company has undoubtedly proved itself as an outstanding training and placement partner, providing top-notch services to aspiring professionals. I got an opportunity in Mumbai based company with package of 3.5 LPA. Thank you Thinkquotient for your support.",
    name: "Gaurav Mahajan",
    image: "",
  },
    // Add more testimonials data as needed
  ];

  const [activeTestimonial, setActiveTestimonial] = useState(0); // Track active testimonial slide index

  const testimonialsRef = useRef(null);

  const testimonialsSettings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,           // Enable autoplay
    autoplaySpeed: 5000,
    beforeChange: (_, next) => setActiveTestimonial(next), // Update active testimonial on change
    
    responsive: [
        {
          breakpoint: 1024, // For screens larger than 1024px
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768, // For screens smaller than 768px
          settings: {
            slidesToShow: 1, // Show 1 slide
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480, // For very small screens
          settings: {
            slidesToShow: 1, // Show 1 slide
            slidesToScroll: 1,
          },
        },
      ],
    };

  const goToTestimonialSlide = (index) => {
    testimonialsRef.current.slickGoTo(index);
  };

  

  return (
    <div>
      {/* Testimonials Section */}
      <div className="pb-20 flex flex-col justify-start height-auto">
      <h1 className="text-3xl text-center font-bold mb-10">Testimonials</h1>
        <div className="center-icon " >
          <img
            src="/assets/images/double-quote.png"
            className="w-10 mx-auto pb-10"
            alt="Testimonial Icon"
          />
        </div>
        <Slider ref={testimonialsRef} {...testimonialsSettings}>
          {testimonialsData.map((testimonial, index) => (
            <div
              key={testimonial.id}
              className={`testimonial-slide ${
                activeTestimonial === index ? "active" : ""
              }`}
            >
              {/* Testimonial Content */}
              <div className="student-testimonial-content">
                <h1 className="text-xl mb-5 text-center">
                  {testimonial.heading}
                </h1>
                <p className="text-gray-500 testimonial-text">
                  {testimonial.text}
                </p>
                <p className="testimonial-name pt-5 px-4">{testimonial.name}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default StudentTestimonials;
