import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import TestimonialsandBlogSec from "../../Components/Home/TestimonialsandBlogSec";
import WhyUs from "../../Components/Home/WhyUs";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import getHelmetContent from "../../Components/Common/HelmetData";
import { Helmet } from "react-helmet";

const CSR = () => {
  useEffect(() => {
    document.title = "CSR - Think Quotient";
    AOS.init();

  }, []);
  const blogData = [
    {
      id: 1,
      image: "/assets/images/csr-1.jpg",
      button: "Read More",
      blogHeading: "Free Job Assistance Drive for Physically Challenged",
      name: "- November 10, 2021",
      link: "https://thinkquotientblogs.blogspot.com/2021/11/why-are-it-companies-hiring-more_10.html",
      text: " We train the eligible candidates FREE OF COST on Full Stack Development. On completion of the course, we send students for placement drive in various ITcompanies. ThinkQuotient feels privileged to be able to help, assist & guide physically challenged people towards getting a good job and means to live a good life. We will continue to assist them in every possible way at ThinkQuotient.",
    },
    {
      id: 2,
      image: "/assets/images/csr-2.jpg",
      button: "Read More",
      blogHeading: "Flood relief Kolhapur-Sangli",
      name: "- July 04, 2021 ",
      link: "https://thinkquotientblogs.blogspot.com/2021/07/covid-19-impact-on-it-industry.html",
      text: "We at ThinkQuotient extended a helping hand by providing monetary help to the victims of flood-affected areas of Kolhapur- Sangli in August 2018 through RISO Foundation. The heavy rains washed away most of the homes and sources of livelihood. Each one of our employee gave their contribution to uplift these flood-hit areas. ThinkQuotient is very proud of every employee who contributed for these people. ",
    },
    {
      id: 3,
      image: "/assets/images/csr-3.jpg",
      button: "Read More",
      blogHeading: "Clothes Donation",
      name: "- July 04, 2021 ",
      link: "https://thinkquotientblogs.blogspot.com/2021/07/covid-19-impact-on-it-industry.html",
      text: "Many are less fortunate than us when it comes to the necessities of life. Many are deprived of food, clothes, shelter, and education, which often drifts them towards a saddening lifestyle. Knowing this fact, we organized a clothes donation drive where our employees got clothes that were donated to RISO Foundation. Every employee of ThinkQuotient actively participated in this drive and contributed generously. ",
    },
  ];

  const blogsRef = useRef(null);

  const blogsSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    beforeChange: (_, next) => setActiveBlog(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const goToBlogSlide = (index) => {
    blogsRef.current.slickGoTo(index);
  };

  const [activeBlog, setActiveBlog] = useState(0);
  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();

  return (

    <>
      <Helmet>
        <meta name="title" content={metaTitle} />
        {/* Remaining meta tags */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Script for Facebook */}
        {helmetContent.script}

        {/* Updated Google Tag Manager */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTNBVH9V');
          `}
        </script>

        {/* Google Analytics Scripts */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "UA-143016865-1");
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-BSFPPN5E9D');
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'AW-574047969');
          `}
        </script>

        {/* Google Tag Manager noscript fallback */}
        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
      </Helmet>

      <div className="overflow-hidden">
        {/* Header Section */}
        <Header />

        {/* Banner Section */}
        <div>
          <div className="csr-banner-sec flex items-center">
            <div className="container text-left text-white mx-auto">
              <div className="md:w-1/2 mx-4 md:mx-0"
                data-aos="fade-up"
                data-aos-duration="1000">
                <h1 className="text-2xl md:text-6xl font-semibold mb-4 ">CSR</h1>
                <p className="text-xl mb-8">
                  Giving is the greatest act of grace. Our team understands the
                  joy of giving...
                </p>
                <Link to="/contact?scrollTo=contact-form">
                  <button className="btn-style-2 px-6 py-2 ">
                    Get In Touch With Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start py-14  bg-gray-100 fade-in-down px-6 "
          data-aos="fade-up"
          data-aos-duration="1000">
          <h1 className="text-2xl font-semibold  text-left mb-10">
            ThinkQuotient’s CSR Initiative
          </h1>
          <div className="md:flex md:space-x-14 space-y-8 md:space-y-0">
            <div>
              <h1 className="text-lg font-semibold mb-3"> When did we start?</h1>
              <p>
                In the year 2018, we have successfully trained and placed three
                students who were physically challenged. These students had very
                good coding skills and logical aptitude that acted as a catalyst
                to skyrocket their IT career.
              </p>
            </div>
            <div>
              <h1 className="text-lg font-semibold mb-3">Why did we start?</h1>
              <p>
                We received an overwhelming response after placing physically
                challenged students. This success encouraged us to organize a Free
                Job Assistance Drive for such students where we could help to
                build their IT career.
              </p>
            </div>
            <div>
              <h1 className="text-lg font-semibold mb-3"> What do we do?</h1>
              <p>
                As a part of our social responsibility, we have organized many
                events that gave us a chance to give back to society. In the past
                year, we have been involved in CSR initiatives like Free Job
                Assistance Drive for Physically Disabled IT Graduates, Flood
                Relief Donation for Kolhapur Sangli, and Cloth Donation.
              </p>
            </div>
          </div>
        </div>

        <div div className="blogs-section py-20 !bg-white">
          <h1 className="text-3xl text-center font-bold mb-10">Acheivements</h1>
          <Slider ref={blogsRef} {...blogsSettings}>
            {blogData.map((blog, index) => (
              <div
                key={blog.id}
                className={`blog-slide !mb-2  rounded-md shadow-md !w-11/12 flex-col ${activeBlog === index ? "active" : ""
                  }`}

              >
                <div className="blog-image w-full">
                  <img
                    src={blog.image}
                    alt={`Blog ${index + 1}`}
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="blog-content w-full space-y-4">
                  <h1 className="md:text-lg font-semibold">{blog.blogHeading}</h1>
                  <p className="blog-text">{blog.text}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* Icons Section */}

        <div className="bg-gray-100">
          <div className="container mx-auto py-16">
            <div className="text-black text-2xl font-bold text-center">
              Traits we look for in physically challenged students
            </div>
            <div className="md:flex justify-evenly py-10">
              <div className="counter-item text-center">
                <div className="h-36 flex items-center justify-center ">
                  <img src="/assets/images/talent.png" className=" w-16" />
                </div>
                <p className="font-bold text-lg  mx-auto ">Talent</p>
              </div>

              <div className="counter-item text-center">
                <div className="h-36 flex items-center justify-center ">
                  <img src="/assets/images/aspiration.png" className=" w-16 " />
                </div>
                <p className="font-bold text-lg mx-auto ">Aspiration</p>
              </div>

              <div className="counter-item text-center">
                <div className="h-36 flex items-center justify-center ">
                  <img
                    src="/assets/images/determination.png"
                    className=" w-16 "
                  />
                </div>
                <p className="font-bold text-lg mx-auto ">Determination</p>
              </div>

              <div className="counter-item text-center">
                <div className="h-36 flex items-center justify-center ">
                  <img src="/assets/images/integration.png" className=" w-16 " />
                </div>
                <p className="font-bold text-lg mx-auto ">Integrity</p>
              </div>

              <div className="counter-item text-center">
                <div className="h-36 flex items-center justify-center ">
                  <img src="/assets/images/equal.png" className=" w-16 " />
                </div>
                <p className="font-bold text-lg mx-auto ">Equality</p>
              </div>

              <div className="counter-item text-center">
                <div className="h-36 flex items-center justify-center ">
                  <img src="/assets/images/positivity.png" className=" w-16 " />
                </div>
                <p className="font-bold text-lg mx-auto ">Positive Attitude</p>
              </div>
            </div>
          </div>
        </div>

        {/* CSR Stories */}
        <div div className="blogs-section py-20 !bg-white">
          <h1 className="text-3xl text-center font-bold mb-10">
            Our CSR stories
          </h1>
          <div className="md:flex justify-center ">
            <div className="blog-slide !mb-2  rounded-md shadow-md flex-col md:w-5/12"
              data-aos="fade-right"
              data-aos-duration="1000">
              <div className="blog-image w-full overflow-hidden "
              >
                <a
                  href="https://www.hindustantimes.com/cities/special-employment-drive-for-disabled-students-in-it-field-on-december-15/story-Q6tBxNPib06pOpB9mjg5cP.html"
                  target="_blank"
                >
                  <img
                    src="/assets/images/csr-4.jpg"
                    alt="Hindustan Times"
                    className="h-[250px] w-full transform transition-transform duration-1000 cursor-pointer hover:scale-125 object-cover"
                  />
                </a>
              </div>
              <div className="blog-content w-full space-y-4" >
                <p className="testimonial-name">13 DECEMBER</p>
                <h1 className="md:text-lg font-semibold">
                  Our news paper coverage:
                </h1>
                <a
                  href="https://www.hindustantimes.com/cities/special-employment-drive-for-disabled-students-in-it-field-on-december-15/story-Q6tBxNPib06pOpB9mjg5cP.html"
                  target="_blank"
                >
                  <button className="btn-style-4 px-6 py-2">Discover More</button>
                </a>
              </div>
            </div>
            <div className="blog-slide !mb-2  rounded-md shadow-md md:w-5/12 flex-col"
              data-aos="fade-left"
              data-aos-duration="1000">
              <div className="blog-image w-full overflow-hidden">
                <a
                  href="https://www.loksatta.com/videos/vishesh-vartankan/2038572/overcoming-disabilitydisabilityovercoming-boredomovercomingdisabilitieslivingliving-with-disabilityovercoming-limitationsdisability-living-allowanceovercoming-challangesovercoming-adversity/"
                  target="_blank"
                >
                  <img
                    src="/assets/images/csr-5.jpg"
                    alt="Loksatta Online"
                    className=" h-[250px] w-full   transform transition-transform duration-1000 cursor-pointer hover:scale-125 object-cover  "
                  />
                </a>
              </div>
              <div className="blog-content w-full space-y-4">
                <p className="testimonial-name">09 DECEMBER</p>

                <h1 className="md:text-lg font-semibold">Our video coverage:</h1>
                <a
                  href="https://www.loksatta.com/videos/vishesh-vartankan/2038572/overcoming-disabilitydisabilityovercoming-boredomovercomingdisabilitieslivingliving-with-disabilityovercoming-limitationsdisability-living-allowanceovercoming-challangesovercoming-adversity/"
                  target="_blank"
                >
                  <button className="btn-style-4 px-6 py-2">Discover More</button>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* First */}
        <div className="m-0 md:flex  service-boxes container md:mx-auto p-6 md:p-0">
          <div className="md:w-1/2   flex flex-col justify-center">
            <h1 className="text-4xl font-bold mb-4">Collaboration</h1>
            {/* <p className="mt-4 w-[80%]">To Build a Franchise network Pan India.</p> */}
            <div className="flex items-center"
              data-aos="fade-right"
              data-aos-duration="1000">
              <div>
                <p className="mt-4  list-items ps-4 text-justify">
                  We are thrilled to announce an exciting collaboration between ThinkQuotient and Birlasoft, aimed at providing exceptional opportunities for differently-abled students to kickstart their careers. At ThinkQuotient, we are deeply committed to our CSR initiative, dedicated to empowering individuals with diverse abilities. Teaming up with Birlasoft, a renowned organization known for its commitment to inclusivity and diversity, we're thrilled to extend a helping hand to talented applicants seeking a chance to excel in their professional journeys. If you or someone you know is differently-abled and ready to seize this incredible opportunity, we invite you to share your details with us. Together, let's pave the way for a more inclusive and equitable future. Join us in spreading the word and making a meaningful impact in the lives of these exceptional individuals.
                </p>
              </div>
            </div>
          </div>
          {/* Right Section */}
          <div className="md:w-1/2  flex justify-end items-center fade-in-right space-x-8"
            data-aos="fade-left"
            data-aos-duration="1000">
            <img
              src="/assets/images/csr1.jpg"
              alt="Image 2"
              className="md:w-4/5 object-cover rounded-md md:mt-28 mt-10 border shadow-md"
            />
          </div>
        </div>

        {/* Seocnd */}
        <div className="bg-gray-100 py-20 my-20">
          <div className="m-0 md:flex  service-boxes container mx-auto ">
            {/* Left Section */}
            <div className="md:w-1/2  flex justify-start items-center fade-in-right "
              data-aos="fade-right"
              data-aos-duration="1000">
              <img
                src="/assets/images/csr2.png"
                alt="Image 2"
                className="w-5/5 object-cover rounded-md border shadow-md"
              />
            </div>
            <div className="md:w-1/2   flex flex-col justify-center md:ps-20 p-6 md:p-0"
              data-aos="fade-left"
              data-aos-duration="1000">
              <h1 className="text-2xl  mb-4">
                <span className="font-bold">Glimpses</span> - Drive for Physically
                Disabled Candidates
              </h1>
              {/* <p className="mt-4 w-[80%]">To Build a Franchise network Pan India.</p> */}
              <div className="flex items-center ">
                <div>
                  <p className="mt-4  list-items ps-4 text-justify">
                    Discover the heartwarming moments captured in our Glimpses section, showcasing the impactful CSR activities undertaken by ThinkQuotient. From empowering differently-abled individuals to supporting community development initiatives, each snapshot reflects our unwavering commitment to social responsibility. Witness the joy of transformation as we strive to make a positive difference in the lives of those in need. Explore our Glimpses gallery and be inspired by the power of compassion and action. Join us in creating a brighter, more inclusive future for all.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Third */}
        <div className="m-0 md:flex  service-boxes container mx-auto md:py-20 px-6 md:px-0">
          <div className="md:w-1/2   flex flex-col justify-center"
            data-aos="fade-right"
            data-aos-duration="1000">
            <h1 className="text-2xl  mb-4">
              <span className="font-bold">Press Coverage</span> - Drive for
              Physically Disabled Candidates
            </h1>
            {/* <p className="mt-4 w-[80%]">To Build a Franchise network Pan India.</p> */}
            <div className="flex items-center">
              <div>
                <p className="mt-4  list-items ps-4 text-justify">
                  ThinkQuotient CSR efforts got recognition from two different publications. Loksatta published our CSR initiative on their online platform. We look forward to continuing our CSR efforts so that people are provided the necessary help.
                  <br /> <br />
                  ThinkQuotient CSR efforts got recognition from two different publications. Hindustan Times published it in their newspaper hardcopy. We look forward to continuing our CSR efforts so that people are provided the necessary help.

                </p>
              </div>
            </div>
          </div>
          {/* Right Section */}
          <div className="md:w-2/3  md:flex justify-end items-center fade-in-right md:space-x-8 py-10 md:py-0"
            data-aos="fade-left"
            data-aos-duration="1000">
            <img
              src="/assets/images/csr3.jpg"
              alt="Image 2"
              className="md:w-2/5 object-cover rounded-md border shadow-md"
            />
            <img
              src="/assets/images/csr4.png"
              alt="Image 2"
              className="md:w-2/5 object-cover rounded-md border shadow-md mt-8 md:mt-0"
            />
          </div>
        </div>

        {/* Fourth */}
        <div className="bg-gray-100 py-20 my-20 px-3 md:mx-0">
          <div className="m-0 md:flex  service-boxes container mx-auto ">
            {/* Left Section */}
            <div className="md:w-1/2  flex justify-start items-center fade-in-right "
              data-aos="fade-right"
              data-aos-duration="1000">
              <img
                src="/assets/images/csr5.png"
                alt="Image 2"
                className="w-5/5 object-cover rounded-md border shadow-md md:p-20 p-6 bg-white"
              />
            </div>
            <div className="md:w-1/2   flex flex-col justify-center md:ps-20 p-6 md:p-0"
              data-aos="fade-left"
              data-aos-duration="1000">
              <h1 className="text-2xl  mb-4 font-semibold">
                Placed Physically Disabled Students
              </h1>
              {/* <p className="mt-4 w-[80%]">To Build a Franchise network Pan India.</p> */}
              <div className="flex items-center ">
                <div>
                  <p className="mt-4  list-items ps-4">
                    <span className="font-bold">Bhojraj Sarsambi - </span>
                    Placed in TIAA – Permanent Lower Body Disability
                  </p>
                  <p className="mt-4  list-items ps-4">
                    <span className="font-bold">Shifa Patware - </span>
                    Barclays – 90% Hearing Disability
                  </p>
                  <p className="mt-4  list-items ps-4">
                    <span className="font-bold">Atish Dhamnaskar - </span>
                    JIEM
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <TestimonialsandBlogSec />
        <ClientLogoCarousel />

        <Footer />
      </div>
    </>
  );
};

export default CSR;
