import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaUserCheck, FaInstalod,FaLayerGroup, FaPrayingHands  } from "react-icons/fa";
import { FaHandsHelping } from "react-icons/fa";
import { FaLaptop } from "react-icons/fa";

const GridSection = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="flex flex-col items-center pt-10">
      {/* Heading in the center */}
      <h1 className="text-3xl font-bold mb-4 text-center">
  Accelerate Your Data Journey with ThinkQuotient Generative AI & KNIME Training Services
</h1>
{/* New Section */}
  <div className="flex flex-col xl:flex-row mt-16 2xl:px-36 px-2 md:px-10 justify-between overflow-hidden pb-20 min-h-screen">

    {/* Left Section */}
    <div className="xl:w-6/12 flex flex-col justify-center p-4 flex-1" data-aos="fade-right">
    <div className="flex flex-col md:flex-row items-center justify-between bg-gray-600 text-white p-5 mb-7">
    <div className="flex items-center mb-4 md:mb-0">
      <img src="/assets/images/machine-learning.png" alt="Generative AI Logo" className="h-12 w-12 md:h-14 md:w-14 mr-4" />
      <div>
        {/* <h3 className="text-base md:text-lg mb-1 md:mb-2">Open For Innovation</h3> */}
        <h1 className="text-xl md:text-2xl lg:text-3xl font-bold">Generative AI & KNIME</h1>
      </div>
    </div>
    {/* <div className="bg-gray-600 p-3 md:p-4 lg:p-5 text-center md:text-right">
      <h2 className="text-lg md:text-xl lg:text-2xl font-medium">Certified</h2>
    </div> */}
  </div>
      <h1 className="text-base md:text-lg mb-4 font-bold">At ThinkQuotient, we stand out with our:</h1>
      <ul className="custom-list mb-4">
        <li className="expert-instructors">
          <div className="icon-wrapper flex items-center mb-2">
            <FaPrayingHands  className="icon mr-2 text-xl md:text-2xl" />
            <span className="text-base md:text-lg">Our Course Offers:</span>
          </div>
          <div className="description text-sm md:text-base">
          Python Programming, Data Science, Machine Learning, Generative AI (like ChatGPT, DALL·E, and GANs),  KNIME Analytics Platform
          </div>
        </li>
        <li className="expert-instructors">
          <div className="icon-wrapper flex items-center mb-2">
            <FaLayerGroup  className="icon mr-2 text-xl md:text-2xl" />
            <span className="text-base md:text-lg">Industry-Relevant Curriculum:</span>
          </div>
          <div className="description text-sm md:text-base">
            Designed by experts to meet current job market demands.
          </div>
        </li>
        <li className="expert-instructors">
          <div className="icon-wrapper flex items-center mb-2">
            <FaUserCheck className="icon mr-2 text-xl md:text-2xl" />
            <span className="text-base md:text-lg">Expert Instructors:</span>
          </div>
          <div className="description text-sm md:text-base">
            Learn from industry leaders with extensive experience in data analytics and KNIME.
          </div>
        </li>
        <li className="expert-instructors">
          <div className="icon-wrapper flex items-center mb-2">
            <FaLaptop className="icon mr-2 text-xl md:text-2xl" />
            <span className="text-base md:text-lg">Hands-On Experience:</span>
          </div>
          <div className="description text-sm md:text-base">
            Work on real-world projects and a capstone project to showcase your skills.
          </div>
        </li>
        <li className="expert-instructors">
          <div className="icon-wrapper flex items-center mb-2">
            <FaHandsHelping className="icon mr-2 text-xl md:text-2xl" />
            <span className="text-base md:text-lg">Continuous Support:</span>
          </div>
          <div className="description text-sm md:text-base">
            Access resources, forums, and mentorship to guide you through your learning journey and beyond.
          </div>
        </li>
      </ul>
      <p className="text-base md:text-lg mb-4">
        <Link to={'/contact?fromKNIME=true'} className="join-link text-blue-500 underline">Join us</Link> and embark on your journey towards data-driven success!
      </p>
      <div className="flex justify-end p-5">
        <Link to="/training/generativeAI-KNIMETraining">
          <button className="btn-style-0 px-4 py-2 text-sm md:text-base">View More</button>
        </Link>
      </div>
    </div>

    {/* Right Section */}
    {/* <div className="md:w-6/12 p-8 flex justify-center items-center flex-1 relative fade-in-right">
      <img
        src="/assets/images/futurism-perspective-digital-nomads-lifestyle.jpg"
        alt="Image 1"
        className="w-full h-full object-cover rounded-md mr-4"
        data-aos="fade-left"
      />
      <img
        src="/assets/images/One.jpeg"
        alt="Image 2"
        className="h-16 w-16 md:h-40 md:w-40 rounded-md mt-10 md:mt-28 absolute bottom-8 md:bottom-28 right-0"
      />
    </div> */}
    <div className="xl:w-6/12 p-4 flex justify-center items-center  fade-in-right">
      <img
        src="/assets/images/datascienceGenAIKNIME.jpeg"
        alt="Image 1"
        className="w-full h-full object-inherit  rounded-md mr-4"
        data-aos="fade-left"
      />
    </div>
  </div>
       {/* Heading in the center */ }
  <h1 className="text-3xl font-bold mb-14 text-center">Our Expertise & Solutions</h1>

  {/* Grid Divisions */ }
  <div className="hidden md:flex w-full px-4 md:px-0 ">
    {/* First 25% */}
    <div
      className="md:w-1/4 flex flex-col h-full "
    // data-aos="fade-right"
    // data-aos-offset="300"
    // data-aos-easing="ease-in-sine"
    >
      <Link to="/services/productdevelopment">
        <div className="h-1/2 mb-2">
          {/* First Horizontal Section */}
          <div className="product-development-sec">
            <h3 className="text-xl font-bold  text-white  p-10 flex items-end z-50 absolute">
              Product
              <br />
              Development
            </h3>
          </div>
        </div>
      </Link>
      <Link to="/services/outsourcing">
        <div className="h-1/2">
          {/* Second Horizontal Section */}
          <div className="outsourcing-sec">
            <h3 className="text-xl font-bold mb-2  text-white flex justify-center items-center absolute z-50 m-10">
              Outsourcing
            </h3>
          </div>
        </div>
      </Link>
    </div>

    {/* Second 25% */}
    <div
      className="md:w-1/4 "
    // data-aos="fade-up"
    // data-aos-offset="300"
    // data-aos-easing="ease-in-sine"
    >
      <Link to="/services/technologyconsulting">
        {/* Vertical Section */}
        <div className="flex flex-col h-[300px] md:h-full justify-center items-center w-full">
          <div className="technology-consulting-sec">
            <h3 className="text-xl font-bold mb-2  text-white flex justify-center items-center text-center absolute z-50 top-[50%] ms-20">
              Technology <br />
              Consulting
            </h3>
          </div>
        </div>
      </Link>
    </div>

    {/* Third 50% */}
    <div
      className="md:w-1/2"
    // data-aos="fade-down"

    >
      {/* First Vertical Section */}
      <div className="w-full md:flex flex-row fade-in-up mb-2">
        <div className="web-development-sec">
          <Link to="/services/productdevelopment?scrollTo=web-developement">
            {/* <a href="#web-development"> */}
            <h3 className="text-xl font-bold text-white flex justify-center items-center text-center absolute z-50 top-[20%] p-14">
              Web <br /> Development
            </h3>
            {/* </a> */}
          </Link>
        </div>
        <div className="knibe-sec ">
          <Link to="/services/knime">
            <h3 className="text-xl font-bold text-white flex justify-center items-center z-50 absolute top-[20%] p-14">
              KNIME
            </h3>
          </Link>
        </div>
      </div>

      {/* Second Vertical Section */}
      <div className="w-full md:flex flex-row fade-in-down">
        <div className="software-development-sec">
          <Link to="/services/productdevelopment?scrollTo=software-development">
            <h3 className="text-xl font-bold mb-2   text-white flex justify-center items-center text-center absolute z-50 top-[20%] p-14">
              Software <br /> Development
            </h3>
          </Link>
        </div>
        <div className="app-development-sec">
          <Link to="/services/productdevelopment?scrollTo=app-development">
            <h3 className="text-xl font-bold mb-2   text-white flex justify-center items-center text-center absolute z-50 top-[20%] p-14">
              App <br /> Development
            </h3>
          </Link>
        </div>
      </div>
    </div>
  </div>

  {/* Grid Divisions For Mobile screen*/ }
  <div className="md:hidden w-full px-1 md:px-0">
    {/* First 25% */}
    <div className="w-full flex flex-col h-full "
    // data-aos="fade-left"

    >
      <div className=" mb-2 flex space-x-2">
        {/* First Horizontal Section */}
        <div className="w-1/2 ">
          <div className="product-development-sec">
            <Link to="/services/productdevelopment">
              <h3 className="md:text-xl text-sm font-bold  text-white  p-10 flex items-end z-50 absolute">
                Product
                <br />
                Development
              </h3>
            </Link>
          </div>
          <div className="h-1/2 mt-2">
            {/* Second Horizontal Section */}
            <div className="outsourcing-sec">
              <Link to="/services/outsourcing">
                <h3 className="md:text-xl text-sm font-bold mb-2  text-white flex justify-center items-center absolute z-50 m-10">
                  Outsourcing
                </h3>
              </Link>
            </div>
          </div>
        </div>

        {/* Vertical Section */}
        <div className="w-1/2 ">
          <div className="technology-consulting-sec">
            <Link to="/services/technologyconsulting">
              <h3 className="md:text-xl text-sm font-bold mb-2  text-white flex justify-center items-center text-center relative z-50 top-[50%] ms-10">
                Technology <br />
                Consulting
              </h3>
            </Link>
          </div>
        </div>
      </div>
    </div>

    {/* Third 50% */}
    <div className="w-full flex"
    // data-aos="fade-right"
    >
      {/* First Vertical Section */}
      <div className="w-full md:flex flex-row fade-in-up mb-2 me-2">
        <div className="web-development-sec !h-[200px]">
          <Link to="/services/productdevelopment?scrollTo=web-developement">
            <h3 className="md:text-xl text-sm font-bold text-white flex justify-center items-center text-center absolute z-50 top-[40%] ms-14">
              Web <br /> Development
            </h3>
          </Link>
        </div>
        <div className="knibe-sec !h-[200px] mt-2">
          <Link to="/services/knime">
            <h3 className="md:text-xl text-sm font-bold text-white flex justify-center items-center z-50 absolute top-[40%] ms-14">
              Knime
            </h3>
          </Link>
        </div>
      </div>

      {/* Second Vertical Section */}
      <div className="w-full md:flex flex-row fade-in-down">
        <div className="software-development-sec !h-[200px]">
          <Link to="/services/productdevelopment?scrollTo=software-development">
            <h3 className="md:text-xl text-sm font-bold mb-2   text-white flex justify-center items-center text-center absolute z-50 top-[40%] ps-14">
              Software <br /> Development
            </h3>
          </Link>
        </div>
        <div className="app-development-sec !h-[200px] mt-2">
          <Link to="/services/productdevelopment?scrollTo=app-development">
            <h3 className="md:text-xl text-sm font-bold mb-2   text-white flex justify-center items-center text-center absolute z-50 top-[40%] ps-14">
              App <br /> Development
            </h3>
          </Link>
        </div>
      </div>
    </div>
  </div>

  {/* New Section */ }
  <div className="w-full px-2 md:px-5 my-16 space-y-2"
  // data-aos="fade-up"

  >
    <h1 className="text-3xl font-bold mb-14 text-center">
      Industries We Serve
    </h1>

    {/* Second Grid Section */}
    <div className="hidden md:flex w-full  md:px-0 md:space-x-4 "


    >
      {/* First 25% */}
      <div className="md:w-1/4 flex flex-col h-full fade-in-left">
        <div className=" mb-2">
          {/* First Horizontal Section */}
          <h3
            className="text-xl font-bold  text-white industries-we-serve p-10 flex items-end z-50 "
            style={{ backgroundImage: "url(/assets/images/banking.jpg)" }}
          >
            <span className="opacity-90">
              Banking &
              <br />
              Financial Services
            </span>
          </h3>
        </div>
      </div>
      {/* First 25% */}
      <div className="md:w-1/4 flex flex-col h-full fade-in-left">
        <div className=" mb-2">
          {/* First Horizontal Section */}
          <h3
            className="text-xl font-bold  text-white industries-we-serve p-10 flex items-end "
            style={{ backgroundImage: "url(/assets/images/insurance.jpg)" }}
          >
            <span className="opacity-90">Insurance</span>
          </h3>
        </div>
      </div>
      {/* First 25% */}
      <div className="md:w-1/4 flex flex-col h-full fade-in-left">
        <div className=" mb-2">
          {/* First Horizontal Section */}
          <h3
            className="text-xl font-bold  text-white industries-we-serve p-10 flex items-end"
            style={{
              backgroundImage: "url(/assets/images/healthcare.jpg)",
            }}
          >
            {" "}
            <span className="opacity-90">Healthcare</span>
          </h3>
        </div>
      </div>
      {/* First 25% */}
      {/* First 25% */}
      <div className="md:w-1/4 flex flex-col h-full fade-in-left">
        <div className=" mb-2">
          {/* First Horizontal Section */}
          <h3
            className="text-xl font-bold  text-white industries-we-serve p-10 flex items-end"
            style={{ backgroundImage: "url(/assets/images/software.jpg)" }}
          >
            <span className="opacity-90">
              Software &
              <br />
              Hi-Tech
            </span>
          </h3>
        </div>
      </div>
    </div>

    <div className="hidden md:flex w-full  md:px-0 md:space-x-4"


    >
      {/* First 25% */}
      <div className="md:w-1/4 flex flex-col h-full fade-in-left">
        <div className=" mb-2">
          {/* First Horizontal Section */}
          <h3
            className="text-xl font-bold  text-white industries-we-serve p-10 flex items-end"
            style={{ backgroundImage: "url(/assets/images/consumert.jpg)" }}
          >
            <span className="opacity-90">Consumer Tech</span>
          </h3>
        </div>
      </div>
      {/* First 25% */}
      <div className="md:w-1/4 flex flex-col h-full fade-in-left">
        <div className=" mb-2">
          {/* First Horizontal Section */}
          <h3
            className="text-xl font-bold  text-white industries-we-serve p-10 flex items-end"
            style={{ backgroundImage: "url(/assets/images/lifesc.jpg)" }}
          >
            <span className="opacity-90">Life Sciences</span>
          </h3>
        </div>
      </div>
      {/* First 25% */}
      <div className="md:w-1/4 flex flex-col h-full fade-in-left">
        <div className=" mb-2">
          {/* First Horizontal Section */}
          <h3
            className="text-xl font-bold  text-white industries-we-serve p-10 flex items-end"
            style={{
              backgroundImage: "url(/assets/images/industrial.jpg)",
            }}
          >
            <span className="opacity-90">Industrial</span>
          </h3>
        </div>
      </div>
      <div className="md:w-1/4 flex flex-col h-full fade-in-left">
        <div className=" mb-2">
          {/* First Horizontal Section */}
          <h3
            className="text-xl font-bold  text-white industries-we-serve p-10 flex items-end"
            style={{ backgroundImage: "url(/assets/images/t&m.jpg)" }}
          >
            <span className="opacity-90">Telecom & Media</span>
          </h3>
        </div>
      </div>
    </div>

    {/* Second Grid Section For Mobile screen*/}
    <div className=" md:hidden w-full">
      {/* First 25% */}
      <div className="w-full flex space-x-2">
        <div className="flex  h-full fade-in-left">
          <div className=" mb-2">
            {/* First Horizontal Section */}
            <h3
              className="md:text-xl text-sm font-bold  text-white industries-we-serve !h-[200px] py-4 flex items-end z-50 "
              style={{ backgroundImage: "url(/assets/images/banking.jpg)" }}
            >
              <span className="opacity-90 mx-auto w-10/12">
                Banking & Financial Services
              </span>
            </h3>
          </div>
        </div>
        {/* First 25% */}
        <div className="w-1/2 flex flex-col h-full fade-in-left">
          <div className=" mb-2">
            {/* First Horizontal Section */}
            <h3
              className="md:text-xl text-sm font-bold  text-white industries-we-serve !h-[200px] p-4 flex items-end "
              style={{
                backgroundImage: "url(/assets/images/insurance.jpg)",
              }}
            >
              <span className="opacity-90">Insurance</span>
            </h3>
          </div>
        </div>
      </div>
      <div className="w-full flex space-x-2">
        {/* First 25% */}
        <div className="w-1/2 flex flex-col h-full fade-in-left">
          <div className=" mb-2">
            {/* First Horizontal Section */}
            <h3
              className="md:text-xl text-sm font-bold  text-white industries-we-serve !h-[200px] p-4 flex items-end"
              style={{
                backgroundImage: "url(/assets/images/healthcare.jpg)",
              }}
            >
              <span className="opacity-90">Healthcare</span>
            </h3>
          </div>
        </div>
        {/* First 25% */}
        <div className="w-1/2 flex flex-col h-full fade-in-left">
          <div className=" mb-2">
            {/* First Horizontal Section */}
            <h3
              className="md:text-xl text-sm font-bold  text-white industries-we-serve !h-[200px] p-4 flex items-end"
              style={{
                backgroundImage: "url(/assets/images/software.jpg)",
              }}
            >
              <span className="opacity-90">
                Software &
                <br />
                Hi-Tech
              </span>
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div className="md:hidden w-full ">
      <div className="flex space-x-2">
        {/* First 25% */}
        <div className="w-1/2 flex flex-col h-full fade-in-left">
          <div className=" mb-2">
            {/* First Horizontal Section */}
            <h3
              className="md:text-xl text-sm  !h-[200px] font-bold  text-white industries-we-serve  p-4 flex items-end"
              style={{
                backgroundImage: "url(/assets/images/consumert.jpg)",
              }}
            >
              <span className="opacity-90">Consumer Tech</span>
            </h3>
          </div>
        </div>
        {/* First 25% */}
        <div className="w-1/2 flex flex-col h-full fade-in-left">
          <div className=" mb-2">
            {/* First Horizontal Section */}
            <h3
              className="md:text-xl text-sm  !h-[200px] font-bold  text-white industries-we-serve p-4 flex items-end"
              style={{ backgroundImage: "url(/assets/images/lifesc.jpg)" }}
            >
              <span className="opacity-90">Life Sciences</span>
            </h3>
          </div>
        </div>
      </div>
      {/* First 25% */}
      <div className="flex space-x-2">
        <div className="w-1/2 flex flex-col h-full fade-in-left">
          <div className=" mb-2">
            {/* First Horizontal Section */}
            <h3
              className="md:text-xl text-sm  !h-[200px] font-bold  text-white industries-we-serve p-4 flex items-end"
              style={{
                backgroundImage: "url(/assets/images/industrial.jpg)",
              }}
            >
              <span className="opacity-90">Industrial</span>
            </h3>
          </div>
        </div>
        <div className="w-1/2 flex flex-col h-full fade-in-left">
          <div className=" mb-2">
            {/* First Horizontal Section */}
            <h3
              className="md:text-xl text-sm  !h-[200px] font-bold  text-white industries-we-serve p-4 flex items-end"
              style={{ backgroundImage: "url(/assets/images/t&m.jpg)" }}
            >
              <span className="opacity-90" id="web-development">Telecom & Media</span>
            </h3>
          </div>
        </div>
      </div>
    </div>

    {/* First Row */}
    {/* <div className="md:flex">
          <div className="md:w-1/3 grid-section-item banking-sec ">
            <h3 className="text-black text-2xl  flex">
              Banking &<br />
              Financial
              <br />
              Services
            </h3>
          </div>
          <div className="md:w-1/3 grid-section-item insurance-sec">
            <h3 className="text-black text-2xl  flex ">Insurance</h3>
          </div>
          <div className="md:w-1/3 grid-section-item healthcare-sec">
            <h3 className="text-black text-2xl  flex">Healthcare</h3>
          </div>
        </div> */}

    {/* Second Row */}
    {/* <div className="md:flex">
          <div className="md:w-1/2 grid-section-item">
            <h3 className="text-black text-2xl  flex telecom-sec">
              Telecom & Media
            </h3>
          </div>
          <div className="md:w-1/2 grid-section-item">
            <h3 className="text-black text-2xl  flex consumer-sec">
              Consumer Tech
            </h3>
          </div>
        </div> */}

    {/* Third Row */}
    {/* <div className="md:flex">
          <div className="md:w-1/3 grid-section-item">
            <h3 className="text-black text-2xl  flex science-sec">
              Life Sciences
            </h3>
          </div>
          <div className="md:w-1/3 grid-section-item">
            <h3 className="text-black text-2xl  flex industrial-sec">
              Industrial
            </h3>
          </div>
          <div className="md:w-1/3 grid-section-item">
            <h3 className="text-black text-2xl  flex tech-sec">
              Software &<br />
              Hi-Tech
            </h3>
          </div>
        </div> */}
  </div>
    </div >
  );
};

export default GridSection;
