import React, { useEffect, useRef } from "react";
import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
// import {
//   Tabs,
//   TabsHeader,
//   TabsBody,
//   Tab,
//   TabPanel,
// } from "@material-tailwind/react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { motion } from "framer-motion";
import TestimonialsandBlogSec from "../../Components/Home/TestimonialsandBlogSec";
import WhyUs from "../../Components/Home/WhyUs";
import AOS from "aos";
import "aos/dist/aos.css";
import getHelmetContent from "../../Components/Common/HelmetData";
import { Helmet } from "react-helmet";

const TechnologyConsulting = () => {
  useEffect(() => {
    document.title = "Technology Consulting - Think Quotient";
    AOS.init();
  }, []);

  const [activeTab, setActiveTab] = React.useState("tab1");
  const [tabIndex, setTabIndex] = React.useState(0)
  const data = [
    {
      count: "01",
      label: "Enhance Technological Experiences",
      value: "tab1",
      image: "/assets/images/ete.jpg",
      desc: `We're attuned to your specific audience and their behaviors. Our meticulous approach to user interface and user experience (UI/UX) design ensures that your digital. `,
    },
    {
      count: "02",
      label: "Simplify Learning Processes",
      value: "tab2",
      image: "/assets/images/slp.jpg",
      desc: `We create user-friendly products that simplify learning across various sectors, especially for educational institutions. Our solutions make the learning experience accessible and efficient.`,
    },
    {
      count: "03",
      label: "Gain Industry Recognition",
      value: "tab3",
      image: "/assets/images/gir.jpg",
      desc: `Staying at the forefront of technology and deepening our understanding of industry dynamics, our services elevate your industry exposure, helping you stay relevant and competitive.`,
    },
    {
      count: "04",
      label: "Seamless Technology Upgrades",
      image: "/assets/images/stu.jpg",
      value: "tab4",
      desc: `Whether you're a non-profit organization or a small to large-scale business, we manage technology upgrades seamlessly, without disruption to your operations or revenue streams.`,
    },
    {
      count: "05",
      label: "Digitize Complex Financial Operations",
      image: "/assets/images/dcfo.jpg",
      value: "tab5",
      desc: `With our comprehensive digital engineering services, we leave no stone unturned in digitizing intricate financial processes. This saves you time and effort while ensuring accuracy and compliance.`,
    },
    {
      count: "06",
      label: "Drive Revenue Growth",
      image: "/assets/images/drg.jpg",
      value: "tab6",
      desc: `Our expertise in digital business transformation not only streamlines operations but also significantly boosts your revenue by reducing costs and enhancing operational efficiency.`,
    },
    {
      count: "07",
      label: "Leverage Data for Informed Decisions",
      image: "/assets/images/ldid.jpg",
      value: "tab7",
      desc: `Our services and solutions empower you to make well-informed, data-backed decisions for growth and expansion. Harness the power of analytics and reporting to drive your business forward.`,
    },
    {
      count: "08",
      label: "Attain a Competitive Edge",
      image: "/assets/images/ace.jpg",
      value: "tab8",
      desc: `Benefit from organized operations, improved decision-making, enhanced customer experiences, and a higher competitive advantage. Partner with us to lead your industry confidently.`,
    },
  ];

  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();

  const fadeVariants = {
    initial: { opacity: 0, y: 10 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: -10, transition: { duration: 0.5 } },
  };

  const scrollRef = useRef(null); // Reference to the content container

  const handleTabClick = (index) => {
    setTabIndex(index);
    // Scroll to the container
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (

    <>
      <Helmet>
        <meta name="title" content={metaTitle} />
        {/* Remaining meta tags */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Script for Facebook */}
        {helmetContent.script}

        {/* Updated Google Tag Manager */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTNBVH9V');
          `}
        </script>

        {/* Google Analytics Scripts */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "UA-143016865-1");
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-BSFPPN5E9D');
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'AW-574047969');
          `}
        </script>

        {/* Google Tag Manager noscript fallback */}
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript>
      </Helmet>

      <div className="services-2">
        {/* Header Section */}
        <Header />

        {/* Banner Section */}
        <div>
          <div className="technC-banner-sec flex items-center">
            <div className="container text-left text-white mx-auto">
              <div
                className="md:w-1/2 mx-4 md:mx-0"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h1 className="text-2xl md:text-4xl font-semibold mb-4 ">
                  We're your dedicated partner in navigating the dynamic world of
                  technology.
                </h1>
                <Link to="/contact?scrollTo=contact-form">
                  <button className="btn-style-2 px-6 py-2 ">
                    Get In Touch With Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="container mx-auto md:py-20 py-10 px-2">
            <div
              className="text-black text-2xl md:text-5xl font-bold text-center md:w-1/2 mx-auto"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Let ThinkQuotient Help You Create Your
              <span className="about-us-banner-txt"> Online Presence</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center py-14 my-10  bg-gray-100 fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <b>
              Our experienced consultants are here to provide strategic insights,
              innovative solutions, and cutting-edge technology guidance.{" "}
            </b>{" "}
            <br /> Whether you're looking to optimize your IT infrastructure,
            leverage emerging technologies, or conquer complex digital challenges,
            ThinkQuotient is your trusted ally. Let's embark on a transformative
            journey together, where technology becomes your competitive advantage.
            Welcome to a future empowered by technology with ThinkQuotient.
          </h1>
        </div>

        {/* Tabs Section */}
        {/* <div className="container mx-auto py-10 px-4">
        <Tabs
          value={activeTab}
          orientation="vertical"
          className="block md:flex"
        >
          <TabsHeader
            className="rounded-none bg-transparent p-0 tablist-div  md:w-[400px]  md:mr-20"
            indicatorProps={{
              className: "bg-transparent shadow-none rounded-none",
            }}
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            {data.map(({ label, value }) => (
              <Tab
                key={value}
                value={value}
                onClick={() => setActiveTab(value)}
                className={
                  activeTab === value ? "font-color-primary active-tab" : ""
                }
              
              >
                <a href="#slide-sec">
                  <h1 className="text-md 2xl:text-lg font-semibold text-left py-2 px-4">
                    {label}
                  </h1>
                </a>
              </Tab>
            ))}
          </TabsHeader>

          <TabsBody
              data-aos="fade-left"
              data-aos-duration="1000"
          >
            <div className="md:hidden" id="slide-sec"></div>
            {data.map(({ value, desc, count, image }) => (
              <TabPanel key={value} value={value}>
                <div className="w-full ">
                  <div className="flex flex-col md:flex-row mt-10 md:mt-0">
                    <img
                      src={image}
                      alt={`Tab Image ${value}`}
                      className="vision-mission-box md:mb-6  md:w-1/2"
                    />
                    <div className="vision-mission-box vm-description-box flex flex-col justify-start md:w-1/2 md:ml-6 p-6">
                      <h1 className="text-4xl md:text-6xl mb-4 md:my-4 font-color-primary">
                        {count}
                      </h1>
                      <p className="text-sm md:text-base">{desc}</p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      </div> */}
        <div className="flex flex-col items-center py-14   fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="text-3xl sm:text-5xl xl:text-3xl font-bold ">
                Features{" "}
              </p>
            </div>
          </h1>
        </div>

        <div className="container  mx-auto py-10 px-4 hidden  lg:block">
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div md:w-1/2"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => setTabIndex(index)} // Update tabIndex on click
                  className={`${tabIndex === index
                      ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                      : "text-gray-500 py-2 px-4"
                    } transition duration-200 ease-in-out transform hover:text-blue-500 hover:scale-105`}
                  style={{
                    cursor: "pointer",
                    color: tabIndex === index ? "blue" : "inherit", // Inline style for color
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl  mb-6 ${tabIndex === index ? "text-blue-500" : "text-gray-500"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>

            <div className="tab-content md:w-1/2 p-4" id="slide-sec">
              {data[tabIndex] && (
                <motion.div
                  key={tabIndex} // Re-run animation on tabIndex change
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={fadeVariants}
                  className="relative w-full"
                >
                  <div className="relative w-full md:w-[20rem] h-64 mb-6 md:mb-0">
                    <div className="w-full h-full bg-gray-200 overflow-hidden rounded-lg">
                      <motion.img
                        src={data[tabIndex].image}
                        alt={`${data[tabIndex].label} image`}
                        className="object-cover w-full h-full rounded-lg"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.5 } }}
                      />
                    </div>

                    <motion.div
                      // className="bg-white shadow-lg p-8 rounded-lg w-full md:w-[calc(100%--3rem)] absolute top-[7rem] left-[12rem] md:h-auto overflow-auto"
                      className="custom-card"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { duration: 0.5 } }}
                    >
                      <p className="text-md md:text-lg">
                        {data[tabIndex].desc}
                      </p>
                    </motion.div>
                  </div>
                </motion.div>
              )}
            </div>
          </Tabs>
        </div>

        <div className="container mx-auto py-10 px-4 md:block lg:hidden">
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div md:w-1/2"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => handleTabClick(index)}   // Update tabIndex on click
                  className={`${tabIndex === index
                      ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                      : "text-gray-500 py-2 px-4"
                    }`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl mb-6 ${tabIndex === index ? "text-blue-500" : "text-gray-500"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>

            <div ref={scrollRef} className="tab-content md:w-1/2 p-4" id="slide-sec" style={{ scrollMarginTop: "8.5rem" }} >
              {data[tabIndex] && (
                <div className="relative w-full">
                  {/* Image and Description Section */}
                  <div className="flex flex-col md:flex-col items-center md:items-start">
                    {/* Image Section */}
                    <div className="relative w-full md:w-[20rem] h-64 mb-6 md:mb-0">
                      <div className="w-full h-full bg-gray-200 overflow-hidden rounded-lg">
                        <img
                          src={data[tabIndex].image}
                          alt={`${data[tabIndex].label} image`}
                          className="object-cover w-full h-full rounded-lg"
                        />
                      </div>
                    </div>

                    {/* Description Section */}
                    <div className="bg-white shadow-lg p-4 rounded-lg w-full md:w-[calc(100%-1rem)] md:ml-0 mt-4 md:mt-0">
                      <p className="text-md md:text-lg">
                        {data[tabIndex].desc}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Tabs>
        </div>



        {/* Floating Divs */}
        <div
          className="container mx-auto pb-10"

        >
          <div className="mt-12 text-center">
            <h1 className="text-xl md:text-2xl font-bold md:w-2/3 mx-auto">
              A Customer-Centric Approach To Bringing Innovations To Your Business
            </h1>
          </div>
          <div className="mt-12 md:flex flex-wrap items-center justify-center space-y-8 md:space-y-0 md:space-x-2">
            {/* First style  */}
            <div className="w-full md:w-[24%]">
              <div className="  justify-center flex ">
                <div className="h-24 w-24 bg-gray-200 rounded-full  flex items-center justify-center ">
                  <img
                    src="/assets/images/font-1.svg"
                    className="h-16 w-16 rounded-full items-center"
                  />
                </div>
              </div>
              <div className="mt-8 pb-12 bg-gray-200 rounded-md shadow-md p-8 mx-6 md:mx-0 ">
                <h3 className="font-bold w-4/6">
                  You Reach Out to ThinkQuotient with Your Vision
                </h3>
                <p className="mt-4 text-[14px] font-normal leading-relaxed w-5/6">
                  It all starts with you making the first move. When you contact
                  ThinkQuotient, you bring your unique vision and requirements to
                  the table, igniting the innovation journey.{" "}
                </p>
              </div>
            </div>

            {/* Second style  */}
            <div className="w-full md:w-[24%]">
              <div className="  justify-center flex ">
                <div className="h-24 w-24 bg-gray-200 rounded-full  flex items-center justify-center ">
                  <img
                    src="/assets/images/font-2.svg"
                    className="h-16 w-16 rounded-full items-center"
                  />
                </div>
              </div>
              <div className="mt-8 pb-12 bg-gray-200 rounded-md shadow-md p-8 mx-6 md:mx-0 md:ml-4">
                <h3 className="font-bold  w-8/12">
                  We Analyze Your Requirements & Craft a Tailored Technology
                  Strategy
                </h3>
                <p className="mt-4 text-sm leading-relaxed font-normal w-5/6">
                  Leveraging our expertise and industry insights, we meticulously
                  analyze your technology needs. Subsequently, we create a
                  customized technology strategy that aligns precisely with your
                  business objectives.{" "}
                </p>
              </div>
            </div>

            {/* Third style  */}
            <div className="w-full md:w-[24%]">
              <div className="justify-center flex ">
                <div className="h-24 w-24 bg-gray-200 rounded-full  flex items-center justify-center ">
                  <img
                    src="/assets/images/font-3.svg"
                    className="h-16 w-16 rounded-full items-center"
                  />
                </div>
              </div>
              <div className="mt-8 pb-12 bg-gray-200 rounded-md shadow-md p-8 mx-6 md:mx-0 md:ml-4">
                <h3 className="font-bold w-10/12">
                  Execution Begins on Deliverables
                </h3>
                <p className="mt-4 text-sm font-normal leading-relaxed w-4/5">
                  With the plan in place, ThinkQuotient's team of skilled
                  professionals springs into action. We initiate the execution
                  phase, utilizing our expertise in technology consulting and
                  innovation.
                </p>
              </div>
            </div>

            {/* Fourth style  */}
            <div className="w-full md:w-[24%]">
              <div className="  justify-center flex ">
                <div className="h-24 w-24 bg-gray-200 rounded-full  flex items-center justify-center ">
                  <img
                    src="/assets/images/font-4.svg"
                    className="h-16 w-16 rounded-full items-center"
                  />
                </div>
              </div>
              <div className="mt-8 pb-12 bg-gray-200 rounded-md shadow-md p-8 mx-6 md:mx-0 md:ml-4">
                <h3 className="font-bold  w-8/12">
                  Precisely Delivered Technology Solutions
                </h3>
                <p className="mt-4 text-sm font-normal w-5/6 leading-relaxed">
                  Your satisfaction is our ultimate goal. We deliver the
                  technology solutions precisely as you envisioned, ensuring they
                  seamlessly integrate with your business operations & objectives.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>

        <TestimonialsandBlogSec />

        <ClientLogoCarousel />
        <Footer />
      </div>
    </>
  );
};

export default TechnologyConsulting;
