import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 

const StickyElements = () => {

    const [isHidden, setIsHidden] = useState(false);

  const toggleVisibility = () => {
    setIsHidden(!isHidden);
  };

  return (
    <div className="mystickyelements-fixed mystickyelements-position-left mystickyelements-position-screen-center mystickyelements-position-mobile-left mystickyelements-on-click mystickyelements-size-large mystickyelements-mobile-size-medium mystickyelements-entry-effect-slide-in mystickyelements-templates-default entry-effect" style={{transition: 'all'}}>
      <div className="mystickyelement-lists-wrap">
        <ul className="mystickyelements-lists mysticky">
          <li className="mystickyelements-minimize" onClick={toggleVisibility}>
            <span
                className="mystickyelements-minimize minimize-position-left minimize-position-mobile-left"
                style={{
                    background: '#000000',
                    display: 'flex',            // Enable flexbox
                    alignItems: 'center',       // Center vertically
                    justifyContent: 'center',   // Center horizontally
                    width: '100%',              // Adjust width if necessary
                    height: '100%',             // Adjust height if necessary
                }}
            > 
               {!isHidden ? (
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 512 512" 
                        width="24" 
                        height="24" 
                        fill="#ffffff"
                    >
                        <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/>
                    </svg>
                    ) : (
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 512 512" 
                        width="24" 
                        height="24" 
                        fill="#ffffff"
                    >
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/>                    </svg>
                    )}

            </span>
          </li>
          <li
            id="mystickyelements-social-phone"
            className={`mystickyelements-social-icon-li mystickyelements-social-phone element-desktop-on element-mobile-on ${isHidden ? 'mystickyelements-hidden' : 'mystickyelements-visible'}`}
          >
            <span
              className="mystickyelements-social-icon social-phone social-custom"
              data-tab-setting="click"
              data-click="0"
              data-mobile-behavior="disable"
              data-flyout="disable"
              style={{ background: '#e76a4b' }}
            >
              <a
                href="tel:+917030055128"
                data-url="tel:+917030055128"
                data-tab-setting="click"
                data-mobile-behavior="disable"
                data-flyout="disable"
                title="Phone"
              >
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width="24"
                  height="24"
                  fill="#000000"
                >
                  <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/>
                </svg> */}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="45" height="45" fill="#000000">
                  <path d="M30.5 22.1c.4-.4.4-1 0-1.4l-9.4-9.4c-.4-.4-1-.4-1.4 0l-3.2 3.2 10.8 10.8L30.5 22.1zM30.9 39.1c-4.9-4.9-7.2-9.9-5.5-11.8L14.5 16.5l-2 2c-1.5 1.5-1.8 3.7-1.1 6.6 3 11.1 22.4 30.5 33.5 33.5 1 .3 1.9.4 2.7.4 1.6 0 2.9-.5 3.8-1.5l2.1-2.1L42.7 44.7C40.8 46.3 35.7 44 30.9 39.1zM58.7 48.9l-9.4-9.4c-.4-.4-1-.4-1.4 0l-3.2 3.2 10.8 10.8 3.2-3.2C59.1 49.9 59.1 49.3 58.7 48.9z"></path>
                </svg>
                
              </a>
            </span>
            <span
              className="mystickyelements-social-text"
              style={{ background: '#e76a4b', color: '#000000' }}
            >
            </span>
          </li>
            {/* Email Icon with SVG */}
            <li id="mystickyelements-social-email" className={`mystickyelements-social-icon-li mystickyelements-social-email element-desktop-on element-mobile-on ${isHidden ? 'mystickyelements-hidden' : 'mystickyelements-visible'}`}>
            <span
              className="mystickyelements-social-icon social-email social-custom"
              data-tab-setting="click"
              data-click="0"
              data-mobile-behavior="disable"
              data-flyout="disable"
              style={{ background: '#e76a4b' }}
            >
              <a href="mailto:support@thinkquotient.com" title="Email">
                {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24" fill="#000000">
                  <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/>
                </svg> */}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="40" height="40" fill="#000000">
                  <g id="email_1_">
                    <path d="M18.761,15.908c1.928-1.83,4.185-2.214,5.738-2.214c0.776,0,1.561,0.102,2.271,0.285V8.46c0-0.393-0.079-0.764-0.197-1.117 c-2.925,2.426-5.853,4.851-8.789,7.267c-0.948,0.789-2.157,1.228-3.398,1.228c-1.24,0-2.449-0.438-3.406-1.234 c-2.922-2.413-5.851-4.84-8.779-7.268C2.08,7.691,2,8.065,2,8.46v10.83c0,1.96,1.58,3.54,3.54,3.54h10.713 C15.975,20.32,16.876,17.694,18.761,15.908z"></path>
                    <path d="M25.59 5.82c-2.98 2.47-5.96 4.94-8.95 7.4-1.31 1.09-3.2 1.09-4.51 0-2.98-2.46-5.96-4.93-8.94-7.4.62-.56 1.44-.9 2.35-.9h17.69C24.14 4.92 24.97 5.26 25.59 5.82zM30.208 18.675c-.701-1.295-1.927-2.299-3.452-2.827-1.446-.503-4.498-.779-6.757 1.366-1.806 1.712-2.485 4.403-1.691 6.697.167.479 1.134 2.917 3.747 3.78.639.211 1.249.29 1.804.29.685 0 1.283-.12 1.741-.255.477-.141.748-.642.607-1.118-.142-.476-.634-.749-1.119-.607-.58.171-1.48.308-2.469-.018-1.812-.598-2.493-2.324-2.611-2.662-.566-1.636-.072-3.565 1.229-4.8 2.047-1.942 4.901-.982 4.929-.971.778.269 1.836.832 2.458 1.981.194.362.626 1.324.423 2.435-.059.314-.138.746-.465 1.1-.309.335-.801.641-1.002.549-.613-.282-.158-2.339-.15-2.369.088-.349.212-.739.369-1.16.175-.465-.062-.984-.527-1.158-.394-.148-.817.012-1.045.346-.577-.423-1.261-.671-1.967-.671-1.012.015-2.013.455-2.557 1.2-.698.886-.858 2.199-.418 3.425.378 1.051 1.14 1.794 2.019 1.984.175.042.356.062.543.062.695 0 1.45-.283 2.13-.784.226.325.505.6.852.759 1.131.522 2.333-.156 3.077-.965.68-.736.83-1.554.911-1.994C31.112 20.678 30.563 19.334 30.208 18.675zM23.703 23.457c-.279-.06-.563-.389-.725-.837-.226-.627-.171-1.312.157-1.729.172-.235.577-.487 1.079-.487.536-.041 1.067.307 1.397.81-.088.454-.132.877-.135 1.268C24.888 23.179 24.124 23.563 23.703 23.457z"></path>
                  </g>
                </svg>


              </a>
            </span>
          
          </li>
             {/* Enquiry Form Icon with SVG */}
             <li
            id="mystickyelements-social-enquiry"
            className={`mystickyelements-social-icon-li mystickyelements-social-enquiry element-desktop-on element-mobile-on ${isHidden ? 'mystickyelements-hidden' : 'mystickyelements-visible'}`}
          >
            <span
              className="mystickyelements-social-icon social-enquiry social-custom"
              data-tab-setting="click"
              data-click="0"
              data-mobile-behavior="disable"
              data-flyout="disable"
              style={{ background: '#e76a4b', borderBottomRightRadius: '10px' }}
            >
              <Link
                to="/contact"
                title="Enquiry Form"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}
              >
                    {/* <svg xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 352 512" width="24" height="24" 
                    fill="#000000"
                    preserveAspectRatio="xMidYMid meet">
                        
                        <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z"/>
                    </svg>               */}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="40" height="40" fill="#000000" id="agenda">
                      <g id="agenda">
                        <path d="M19.63 2v5.801c0 .166-.191.261-.323.159l-2.204-1.687c-.088-.068-.211-.068-.299 0L14.6 7.96c-.132.101-.323.007-.323-.159V2H19.63zM26.49 6.03h-.222v17.944c0 2.096-1.704 3.8-3.8 3.8H7.53V28c0 1.1.9 2 2 2h16.96c1.11 0 2-.9 2-2V8.03C28.49 6.93 27.6 6.03 26.49 6.03z"></path>
                        <path d="M22.468,2H21.43v5.801c0,1.104-0.897,2.001-2.001,2.001c-0.438,0-0.871-0.147-1.22-0.416l-1.255-0.96l-1.26,0.964
                          c-0.348,0.266-0.779,0.412-1.215,0.412c-0.515,0-1.005-0.195-1.378-0.549c-0.396-0.376-0.624-0.905-0.624-1.452V2H5.509
                          c-1.105,0-2,0.895-2,2v19.973c0,1.105,0.895,2,2,2h16.959c1.105,0,2-0.895,2-2V4C24.468,2.895,23.572,2,22.468,2z 
                          M16.954,18.601h-5.932c-0.497,0-0.9-0.403-0.9-0.9s0.403-0.9,0.9-0.9h5.932c0.497,0,0.9,0.403,0.9,0.9S17.451,18.601,16.954,18.601z
                          M19.563,14.717H8.413c-0.497,0-0.9-0.403-0.9-0.9s0.403-0.9,0.9-0.9h11.15c0.497,0,0.9,0.403,0.9,0.9S20.061,14.717,19.563,14.717
                          z"></path>
                      </g>
                    </svg>

                </Link>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default StickyElements;
