import React from "react";
import { Helmet } from "react-helmet";

const getHelmetContent = () => {
  return {
    meta: [
      { name: "keywords", content: "ThinkQuotient specializes in IT development and as well as in freshers training." },
      { name: "description", content: "Think Quotient is an online learning and teaching marketplace with over multiple courses and 10000 students. Learn programming, data science and more." },
      { name: "viewport", content: "width=device-width, initial-scale=1.0" },
      { name: "language", content: "English" },
    ],

    script: [
      <script type="application/ld+json">
        {`
            {
              "@context": "http://www.schema.org",
              "@type": "ProfessionalService",
              "name": " Professional Training Institute in Pune",
              "url": "https://www.thinkquotient.com/",
              "logo": "https://www.thinkquotient.com/assets/images/envelop.png",
              "image": "https://thinkquotient.com/assets/images/envelop.png",
              "description": "ThinkQuotient specializes in IT development and as well as in freshers training.",
              "telephone": "+917030055128",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": " 2nd Floor, Nirmitee Elite, 103, Law College Rd, next to Starbucks, Shanti Sheela Society, Apex Colony, Erandwane, Pune",
                "addressLocality": "Pune",
                "addressRegion": "Maharashtra",
                "postalCode": "411004",
                "addressCountry": "India"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": "18.51455",
                "longitude": "73.82876"
              },
              "sameAs": [
                "https://www.facebook.com/ThinkQuotient-Software-Pvt-Ltd-425080191407703/",
                "https://twitter.com/ThinkQuotient",
                "https://www.linkedin.com/company/thinkquotient/",
                "https://www.instagram.com/thinkquotient_software/?igshid=1j31daue8ovci"
              ]
            }
          `}
      </script>,

      // Updated Google Tag Manager script
      <script>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTNBVH9V');
        `}
      </script>,

      // Google Analytics tracking scripts
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>,
      <script>
        {`
          window.dataLayer = window.dataLayer || []; 
          function gtag() { dataLayer.push(arguments); } 
          gtag('js', new Date()); 
          gtag('config', 'UA-143016865-1');
        `}
      </script>,

      <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>,
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', 'G-BSFPPN5E9D');
        `}
      </script>,

      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>,
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', 'AW-574047969');
        `}
      </script>,
    ],

    noscript: [
      // Google Tag Manager noscript fallback
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>,
    ],
  };
};

export default getHelmetContent;
