import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CorporateTestimonials = () => {
  // Testimonials data
  const testimonialsData = [
    {
      id: 1,
      icon: "path/to/icon-1.png",
      // heading: 'Art Freedom and Creativity',
      text: "Think Quotient Software successfully owned and delivered end to end recruitment, training and delivery within the agreed timeline. Their delivery was prompt with utmost quality and professionalism. Their ability and timely delivery was highly appreciated by our delivery team. Our delivery team is extremely satisfied with the candidates hired from Think Quotient. This was a great experience and I would like to continue our relationship with them. I highly recommend Thinkquotient and their highly capable, friendly, and motivated team. ",
      name: "Rohan Gavade ",
      position: "Manager-Talent Acquisition & Global Talent Acquisition Lead",
      company:"Tata Technologies",
      image: "",
    },

    {
      id: 2,
      icon: "path/to/icon-3.png",
      // heading: 'Testimonial Heading 3',
      text: "Thinkquotient Software has been a reliable partner for Circle Graphics for over two years, providing excellent system administration and software development services. Their expertise in Java, NEO4j, and MySQL has been invaluable in completing projects and driving e-commerce sales. The team's reliability, friendly customer service, and ability to rapidly develop new capabilities have made them a valuable asset. I highly recommend Thinkquotient and their team of highly capable, friendly, and motivated developers.",
      name: "Lars J. Kagerreis",
      position: "Senior Director Customer Data & Analytics",
      company:"Circle Graphics, USA",
      image: "",
    },
   
    // Add more testimonials data as needed
  ];

  const [activeTestimonial, setActiveTestimonial] = useState(0); // Track active testimonial slide index

  const testimonialsRef = useRef(null);

  const testimonialsSettings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,           // Enable autoplay
    autoplaySpeed: 5000,
    beforeChange: (_, next) => setActiveTestimonial(next), // Update active testimonial on change
    
    responsive: [
        {
          breakpoint: 1024, // For screens larger than 1024px
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768, // For screens smaller than 768px
          settings: {
            slidesToShow: 1, // Show 1 slide
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480, // For very small screens
          settings: {
            
            slidesToShow: 1, // Show 1 slide
            slidesToScroll: 1,
          },
        },
      ],
    };

  const goToTestimonialSlide = (index) => {
    testimonialsRef.current.slickGoTo(index);
  };

  

  return (
    <div>
      {/* Testimonials Section */}
      <div className="pb-20 flex flex-col justify-start min-h-[550px] mt-16">
      <h1 className="text-3xl text-center font-bold mb-10">Testimonials</h1>
        <div className="center-icon " >
          <img
            src="/assets/images/double-quote.png"
            className="w-10 mx-auto pb-10"
            alt="Testimonial Icon"
          />
        </div>
        <Slider ref={testimonialsRef} {...testimonialsSettings}>
          {testimonialsData.map((testimonial, index) => (
            <div
              key={testimonial.id}
              className={`testimonial-slide ${
                activeTestimonial === index ? "active" : ""
              }`}
            >
              {/* Testimonial Content */}
              <div className="student-testimonial-content">
                <h1 className="text-xl mb-5 text-center">
                  {testimonial.heading}
                </h1>
                <p className="text-gray-500 testimonial-text">
                  {testimonial.text}
                </p>
                <p className="testimonial-name pt-5 px-4">{testimonial.name}</p>
                <p className="testimonial-name  px-4">{testimonial.position}</p>
                <p className="testimonial-name  px-4">{testimonial.company}</p> 


              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CorporateTestimonials;
