import React, { useEffect } from "react";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import getHelmetContent from "../../Components/Common/HelmetData";

const Brochures = () => {
  useEffect(() => {
    document.title = "Brochures - Think Quotient";
    AOS.init();

  }, []);

  const pdfPaths = ["/assets/images/Revised Brochure2024.pdf"];

  const downloadPDF = (pdfPaths) => {
    pdfPaths.forEach((pdfPath) => {
      const link = document.createElement("a");
      link.href = pdfPath;
      link.download = pdfPath.split("/").pop();
      link.click();
    });
  };

  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();

  return (

    <>
      <Helmet>
        <meta name="title" content={metaTitle} />
        {/* Remaining meta tags */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Script for Facebook */}
        {helmetContent.script}

        {/* Updated Google Tag Manager */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTNBVH9V');
          `}
        </script>

        {/* Google Analytics Scripts */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "UA-143016865-1");
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-BSFPPN5E9D');
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'AW-574047969');
          `}
        </script>

        {/* Google Tag Manager noscript fallback */}
        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
      </Helmet>

      <div className="overflow-hidden">
        {/* Header Section */}
        <Header />
        {/* Banner Section */}
        <div>
          <div className="brochure-banner-sec flex items-center">
            <div className="container text-left text-white mx-auto">
              <div className="md:w-1/2 mx-4 md:mx-0"
                data-aos="fade-up"
                data-aos-duration="1000">
                <h1 className="text-2xl md:text-6xl font-semibold mb-4 ">
                  Brochures
                </h1>
                <Link to="/contact?scrollTo=contact-form">
                  <button className="btn-style-2 px-6 py-2">
                    Get In Touch With Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* text and image box */}

        {/* First */}
        <div className="container mx-auto py-20 px-4 md:px-0">
          {/* First row for larger screens */}
          <div className="md:flex md:space-x-11">
            {/* First image */}
            <div
              className="md:w-1/2 mt-2 flex flex-col items-center"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img
                src="/assets/images/Revised Brochure2024_page-0001.jpg"
                className="object-cover w-full"
                alt="Brochure 1"
              />
            </div>
            {/* Second image */}
            <div
              className="md:w-1/2 mt-2 flex flex-col items-center"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <img
                src="/assets/images/Revised Brochure2024_page-0002.jpg"
                className="object-cover w-full"
                alt="Brochure 2"
              />
            </div>
          </div>

          {/* Third image below the first row */}
          <div
            className="w-full mt-4 flex justify-center"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <div className="md:w-1/2 flex flex-col items-center">
              <img
                src="/assets/images/Revised Brochure2024_page-0003.jpg"
                className="object-cover w-full"
                alt="Brochure 3"
              />
            </div>
          </div>

          {/* Button below the third image */}
          <div className="w-full flex justify-center mt-6">
            <button
              className="btn-style-1 px-6 py-2 md:w-1/4"
              onClick={() => downloadPDF(pdfPaths)}
            >
              Download Brochure
            </button>
          </div>
        </div>


        <Footer />
      </div>
    </>
  );
};

export default Brochures;
