import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import axios from "axios";

import AOS from "aos";
import "aos/dist/aos.css";
import ApiPath from "../../Components/Common/Apiurl";




const CallBackform = () => {
  // Custom Arrow Components

  const [formVisible, setFormVisible] = useState(true);


  const [formError, setFormError] = useState('');
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  const CustomPrevArrow = (props) => (
    <button
      {...props}
      className="absolute top-3/4 2xl:left-36  left-10 transform "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-6 h-6 text-white  mt-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 19l-7-7 7-7"
        />
      </svg>
    </button>
  );

  const CustomNextArrow = (props) => (
    <button
      {...props}
      className="absolute top-3/4 2xl:left-44 left-20 transform "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-6 h-6 text-white mt-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 5l7 7-7 7"
        />
      </svg>
    </button>
  );

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    // autoplay: true,
    // autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // prevArrow: <CustomPrevArrow />,
    // nextArrow: <CustomNextArrow />,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formPayload = {
     
      
      name: e.target.firstname.value,
      email: e.target.email.value.trim(),
      phone: e.target.phone.value,
      message: e.target.message.value.trim(),
      lookingFor: e.target.subject.value,
      queryPageIndex: "CAMPAIGN",
    };
  
    let formIsValid = true;
    const newErrors = {
      name: '',
      email: '',
      phone: '',
      message: '',
      subject: ''
    };
  
    // Validate 'name' field
    if (!formPayload.name.trim()) {
      newErrors.name = 'Name is required';
      formIsValid = false;
    }
  
    // Validate 'email' field
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!formPayload.email.trim()) {
      newErrors.email = 'Email is required';
      formIsValid = false;
    } else if(!emailRegex.test(formPayload.email.trim())) {
      newErrors.email = 'Invalid email format';
      formIsValid = false;
    }
  
    // Validate 'phone' field
const phoneValue = formPayload.phone;
console.log("phoneNo"+phoneValue);
const phoneRegex = /^\d{10}$/; // Regular expression for exactly 10 digits

// if (!phoneValue || phoneValue.length === 0) {
//   newErrors.phone = 'Phone Number is required';
//   formIsValid = false;
// } else 
if ( phoneValue!="" && !phoneRegex.test(phoneValue)) {
  newErrors.phone = 'Phone is must be number';
  formIsValid = false;
}

  
    // Validate 'message' field
    // if (!formPayload.message.trim()) {
    //   newErrors.message = 'Message is required';
    //   formIsValid = false;
    // }
  
    if (!formPayload.lookingFor) {
      newErrors.subject = 'Please select category';
      formIsValid = false;
    }
  
    setErrors(newErrors);
  
    if (!formIsValid) {
      return;
    }
  
    try {
      setLoading(true);
      const response = await fetch(
        ApiPath+"/tqenquiry",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formPayload),
        }
      );
  
      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
  
        setFormVisible(false);
  
        setTimeout(() => {
          // setFormVisible(true);
          setLoading(false);
        }, 3000);
      } else {
        console.error("Error submitting the form", response.statusText);
        setFormError('An error occurred while submitting the form. Please try again later.');
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting the form", error);
      setFormError('An error occurred while submitting the form. Please try again later.');
      setLoading(false);
    }
  };
  



  return (
    <>
   
      <div className="relative">

        {/* Contact Form Section */}
        {formVisible && (
          <div className="absolute top-2 2xl:right-20 right-5 p-8 contact-form-sec fade-in-right hidden md:block">
            <div>
              <h2 className="text-2xl font-semibold mb-4">
                Request a Callback
              </h2>
              <form className="banner-contact-form" onSubmit={handleSubmit}>
                {/* Add your form fields here */}
                <div className="mb-1">
                  <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    placeholder="Name *"
                    className="w-full rounded-md p-2"
                    onChange={handleChange}
                    required
                  />
                  {errors.name && <p className="text-red-500">{errors.name}</p>}
                </div>
                <div className="mb-1">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email *"
                    className="w-full rounded-md p-2"
                    onChange={handleChange}
                    required
                  />
                  {errors.email && <p className="text-red-500">{errors.email}</p>}
                </div>
                <div className="mb-4">
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="Phone Number"
                    className="w-full rounded-md p-2"
                    onChange={handleChange}
                    maxLength={10}
                    // required
                  />
                  {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                </div>

                <div className="mb-4">
                  <select
                    id="subject"
                    name="subject"
                    className="w-full border-b border-stroke px-[5px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
                    onChange={handleChange}
                    required
                  >
                    <option value="" label="Select Category" />
                    {/* <option value="IT Service & Development">
                      IT Service & Development
                    </option> */}
                    <option value="Fullstack Training">
                    Fullstack Training
                    </option>
                    <option value="Corporate Training">Corporate Training</option>
                    {/* <option value="Corporate Collaborations">Corporate Collaborations</option>*/}
                    <option value="Generative AI & KNIME">Generative AI & KNIME</option>
                     {/* <option value="Channel Partner">Channel Partner</option> */}
                  </select>
                  {errors.subject && <p className="text-red-500">{errors.subject}</p>}

                </div>
                <div className="mb-2">
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Write a message "
                    className="w-full rounded-md p-2"
                    onChange={handleChange}
                    // required
                  />
                  {/* {errors.message && <p className="text-red-500">{errors.message}</p>} */}
                </div>
                {/* Add more fields as needed */}
                <button type="submit" className="btn-style-1 w-3/4 sm:w-full py-2 my-4" disabled={loading}>
                  {loading ? 'Submitting...' : 'Submit'}
                </button>
                {formError && <p className="text-red-500">{formError}</p>}
              </form>
            </div>
          </div>
        )}
        {!formVisible && (
          <div className="absolute top-40 2xl:right-20 right-5 p-8 contact-form-sec fade-in-right hidden md:block">
            <h2 className="text-2xl font-semibold mb-4">Thank You!</h2>
            <p className="text-lg mb-4">
              Thank you for contacting us. We'll get back to you soon.
            </p>
            <button
              onClick={() => setFormVisible(true)}
              className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold rounded-full shadow-lg hover:shadow-xl transition-transform transform hover:scale-110 duration-300"
            >
              Close
            </button>
          </div>
        )}
      </div>
      {/* Thank You Message Section */}

      {/* Contact Form Section */}
      <div className=" top-2 2xl:right-20 right-5 p-8 contact-form-sec fade-in-right md:hidden">
        {/* Contact Form Section */}
        {formVisible && (
          <div className=" 2xl:right-20 right-5 p-8 contact-form-sec fade-in-right ">
            <div>
              <h2 className="text-2xl font-semibold mb-4">
                Request a Callback
              </h2>
              <form className="banner-contact-form" onSubmit={handleSubmit}>
                {/* Add your form fields here */}
                <div className="mb-3">
                  <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    placeholder="Name *"
                    className="w-full rounded-md p-2"
                    onChange={handleChange}
                    required
                  />
                  {errors.name && <p className="text-red-500">{errors.name}</p>}
                </div>
                <div className="mb-2">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email *"
                    className="w-full rounded-md p-2"
                    onChange={handleChange}
                    required
                  />
                  {errors.email && <p className="text-red-500">{errors.email}</p>}
                </div>
                <div className="mb-5">
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="Phone Number"
                    className="w-full rounded-md p-2"
                    onChange={handleChange}
                    maxLength={10}
                    // required
                  />
                  {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                </div>

                <div className="mb-2">
                  <select
                    id="subject"
                    name="subject"
                    className="w-full border-b border-stroke px-[5px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
                    onChange={handleChange}
                    required
                  >
                   <option value="" label="Select Category" />
                    {/* <option value="IT Service & Development">
                      IT Service & Development
                    </option> */}
                    <option value="Freshers Training">
                      Freshers Training
                    </option>
                    <option value="Corporate Training">Corporate Training</option>
                    {/* <option value="Corporate Collaborations">Corporate Collaborations</option>*/}
                    <option value="GGenerative AI & KNIME">Generative AI & KNIME</option>
                   {/* <option value="Channel Partner">Channel Partner</option> */}
                  </select>
                  {errors.subject && <p className="text-red-500">{errors.subject}</p>}

                </div>
                <div className="mb-3">
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Write a message "
                    className="w-full rounded-md p-2"
                    onChange={handleChange}
                    required
                  />
                  {/* {errors.message && <p className="text-red-500">{errors.message}</p>} */}
                </div>
                {/* Add more fields as needed */}
                <button type="submit" className="btn-style-1 w-3/4 sm:w-full py-2 my-4" disabled={loading}>
                  {loading ? 'Submitting...' : 'Submit'}
                </button>
                {formError && <p className="text-red-500">{formError}</p>}
              </form>
            </div>
          </div>
        )}
        {!formVisible && (
          <div className=" 2xl:right-20 right-5 p-8 contact-form-sec fade-in-right md:hidden">
            <h2 className="text-2xl font-semibold mb-4">Thank You!</h2>
            <p className="text-lg mb-4">
              Thank you for contacting us. We'll get back to you soon.
            </p>
            <button
              onClick={() => setFormVisible(true)}
              className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold rounded-full shadow-lg hover:shadow-xl transition-transform transform hover:scale-110 duration-300"
            >
              Close
            </button>
          </div>
        )}
      </div>

    </>
  );
};

export default CallBackform;
