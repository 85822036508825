import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import getHelmetContent from "../../Components/Common/HelmetData";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import SuccessStoryCarousel from "../../Components/Home/SuccessStoryCarousel";
import StickyElements from "./StickyElements";
import StudentTestimonials from "./StudentTestimonials";
import Clientele from "./Clientele";
import FreshersBannerSection from "./FreshersBannerSection";

const FreshersTraining = () => {
  const webDevelopmentRef = useRef(null);
  const softwareDevelopmentRef = useRef(null);
  const appDevelopmentRef = useRef(null);

  const pdfPaths = ["/assets/images/Revised Brochure2024.pdf"];
  const [showTooltip, setShowTooltip] = useState(false);
  const [screenSize, setScreenSize] = useState('mobile');

  const downloadPDF = (pdfPaths) => {
    pdfPaths.forEach((pdfPath) => {
      const link = document.createElement("a");
      link.href = pdfPath;
      link.download = pdfPath.split("/").pop();
      link.click();
    });
  };
  useEffect(() => {
    document.title = "Freshers Training - Think Quotient";
    AOS.init();

    // // Check if the URL has the anchor link, and scroll to the section if present
    // const hash = window.location.hash.substring(1);
    // if (hash === "web-development") {
    //   scrollToSection(webDevelopmentRef);
    // } else if (hash === "software-development") {
    //   scrollToSection(softwareDevelopmentRef);
    // } else if (hash === "app-development") {
    //   scrollToSection(appDevelopmentRef);
    // }
  }, []);

  useEffect(() => {
    // Get the entire hash string, including the '?'
    const hashString = window.location.hash;

    // Extract the path and query parameters
    const [path, queryString] = hashString.slice(2).split("?"); // Remove the leading '#/' and split at '?'

    // If you need to parse the query parameters, you can use URLSearchParams
    if (queryString) {
      const queryParams = new URLSearchParams(queryString);
      const scrollToSection = queryParams.get("scrollTo");
      console.log("hello " + scrollToSection);
      // Scroll to the specified section
      if (scrollToSection) {
        const targetSection = document.getElementById(scrollToSection);

        if (targetSection) {
          targetSection.scrollIntoView({ behavior: "smooth" });
        }
      }
    }

    // Rest of your useEffect logic
  }, []);

  useEffect(() => {
    // Get the entire hash string, including the '?'
    const hashString = window.location.hash;

    // Extract the path and query parameters
    const [path, queryString] = hashString.slice(2).split("?"); // Remove the leading '#/' and split at '?'

    // If you need to parse the query parameters, you can use URLSearchParams
    if (queryString) {
      const queryParams = new URLSearchParams(queryString);
      const scrollToSection = queryParams.get("scrollTo");

      // Scroll to the specified section
      if (scrollToSection) {
        const targetSection = document.getElementById(scrollToSection);

        if (targetSection) {
          targetSection.scrollIntoView({ behavior: "smooth" });
        }
      }
    }

    // Rest of your useEffect logic
  }, []);

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [activeTab, setActiveTab] = React.useState("tab1");
  const [tabIndex, setTabIndex] = React.useState(0);
  const data = [
    {
      count: "01",
      label: "Any Graduate can Enroll",
      value: "tab1",
      image: "/assets/images/Anybody Can Enroll 1.png",
      desc: `Any graduate can enroll in our IT courses, regardless of background. Our programs are designed to equip fresh graduates with the essential skills needed to kickstart a career in the tech industry. `,
    },

    {
      count: "02",
      label: "Exposure to Live Projects",
      value: "tab2",
      image: "/assets/images/Project Based Training.png",
      desc: `Learn by doing! Our project-based approach lets you apply IT concepts to real-world scenarios, building hands-on experience and practical skills that prepare you for the workplace.`,
    },
    {
      count: "03",
      label: "Grooming for Interview Preparation",
      image: "/assets/images/Grooming in Interview Preparation.png",
      value: "tab3",
      desc: `Boost your confidence with our interview preparation sessions! From mastering common questions to refining your communication and presentation skills, we’ll help you make a strong impression in any IT job interview.`,
    },
    {
      count: "04",
      label: "2000+ Students Succesfully Placed",
      image: "/assets/images/2000+ Students Placed.png",
      value: "tab4",
      desc: `Join a community of success! With over 2000 students placed in top companies, our training program is trusted to kickstart careers. Gain the skills and support needed to land your dream IT job.`,
    },
    {
      count: "05",
      label: "Online Video Content Available",
      value: "tab5",
      image: "/assets/images/Online Video Content Available 2.png",
      desc: `Access high-quality video lessons anytime, anywhere! Our online content covers key IT topics, making learning flexible and convenient. Study at your own pace and revisit lessons as needed!`,
    },
  ];

  const metaTitle = "Online Courses - Learn Anything";
  const helmetContent = getHelmetContent();

  const fadeVariants = {
    initial: { opacity: 0, y: 10 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: -10, transition: { duration: 0.5 } },
  };

  const scrollRef = useRef(null); // Reference to the content container


  const handleTabClick = (index) => {
    setTabIndex(index);
    // Scroll to the container
    if (screenSize === 'mobile' && scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const [isTabVisible, setIsTabVisible] = useState(false);
  const tabRef = useRef(null); // Create a ref for the tab content

  // Use IntersectionObserver to track tab visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Set visibility state based on tab visibility
        setIsTabVisible(entry.isIntersecting);
      },
      { threshold: 0.5 } // 50% of the tab needs to be visible to consider it visible
    );

    if (tabRef.current) {
      observer.observe(tabRef.current); // Start observing the tab content
    }

    // Cleanup observer when component unmounts or tabRef changes
    return () => {
      if (tabRef.current) {
        observer.unobserve(tabRef.current);
      }
    };
  }, [tabRef]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1024) {
        setScreenSize('desktop'); // Desktop screen
      } else if (width >= 768 && width < 1024) {
        setScreenSize('tablet'); // Tablet screen
      } else {
        setScreenSize('mobile'); // Mobile screen
      }
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Update on resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up listener
    };
  }, []);

  // Set the timer only on large screens
  useEffect(() => {

    if ((screenSize === 'desktop' || screenSize === 'tablet') && isTabVisible) {
      const interval = setInterval(() => {
        setTabIndex((prevIndex) => (prevIndex + 1) % data.length); // Change to the next tab
      }, 5000); // Change tab every 5 seconds

      return () => clearInterval(interval); // Cleanup interval on tab change
    }
  }, [screenSize, isTabVisible, data.length]);

  return (
    <>
      <Helmet>
        {/* Basic Meta Tags */}
        <title>{"Online Courses - Learn Anything"}</title>
        <meta
          name="description"
          content="Jumpstart your IT career with Think Quotient's Freshers Training Program. Master web development, software development, app development, and work on live projects. Join over 2,000 successful students today! IT courses near me, java course, KNIME course near me, java full stack developer course, full stack java developer course, java online course, learn java programming, java classes near me, java full course, oracle java certification, sap classes near me, java script course, java language course, java course near me, java online classes, iit coaching near me, complete java course, java programming basics, java programming course online, full stack java developer training, computing courses near me, best computer institute near me, java programming language course, java scripting course, java language course online, java programming language online course, classes java, java course cost, java programming for interview, knime sql certification."
        />
        <meta
          name="keywords"
          content="e-learning website, best e learning platform, blended learning platform, learn artificial intelligence, machine learning, data science, python, full stack development, java, C#, Dotnet, Testing, QA, React, Angular, think quotient"
        />

        {/* Dynamic Meta Tags */}
        {helmetContent?.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Social Media Sharing */}
        <meta property="og:title" content={metaTitle || "Online Courses - Learn Anything"} />
        <meta
          property="og:description"
          content="Join our Freshers Training program to excel in IT. Learn web, software, and app development with hands-on projects. Trusted by over 2000 placed students."
        />
        <meta
          property="og:image"
          content="https://www.thinkquotient.com/assets/images/freshers-training-banner.png"
        />
        <meta property="og:url" content="https://www.thinkquotient.com/training/fresherstraining" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTitle || "Freshers Training - Think Quotient"} />
        <meta
          name="twitter:description"
          content="Learn essential IT skills with Think Quotient. Hands-on training in web, software, and app development with real projects."
        />
        <meta
          name="twitter:image"
          content="https://www.thinkquotient.com/assets/images/freshers-training-banner.png"
        />

        {/* Robots and Author */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Think Quotient" />

        {/* Updated Google Tag Manager */}
        <script>
          {`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-KTNBVH9V');
    `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "UA-143016865-1");
    `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-BSFPPN5E9D');
    `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'AW-574047969');
    `}
        </script>

        {/* Google Tag Manager Noscript */}
        <noscript>
          {`
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V"
        height="0"
        width="0"
        style="display:none;visibility:hidden"
      ></iframe>
    `}
        </noscript>
      </Helmet>


      <div className="overflow-hidden">

        {/* Header Section */}
        <Header />

        {/* Banner Section */}
        {/* <div>
          <div className="fresherstraining-banner-sec flex ">
            <div className="container text-left text-white mx-auto mt-[5rem]">
              <div
                className="md:w-1/2 mx-4 md:mx-0"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h1 className="text-2xl md:text-4xl font-semibold mb-4">
                  Kickstart Your IT Journey:
                </h1>
                <p className="md:text-xl mb-8">
                Empowering Minds, Shaping Tomorrows
                </p>
                <Link to="/contact?scrollTo=contact-form">
                  <button className="btn-style-2 px-6 py-2 ">
                    Get In Touch With Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div> */}
        <FreshersBannerSection />

        <div className="flex flex-col items-center py-14  bg-gray-100 fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="font-bold text-2xl ">
                Empowering Knowledge and Opportunities at ThinkQuotient{" "}
              </p>
            </div>
            <br /> At ThinkQuotient Software, we believe in empowering every
            learner. Whether you're a recent graduate stepping into the IT
            industry or a corporate professional seeking growth, our training
            programs are designed to shape your skills and open doors to endless
            IT opportunities. Best place for freshers to start their IT journey
          </h1>
        </div>

        <div className="flex flex-col items-center py-14  fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="font-bold text-2xl ">
                Technology Training Programs with Live Projects{" "}
              </p>
            </div>
          </h1>
        </div>

        {/* text and image box */}

        {/* First */}
        <div className="m-0 px-0 flex flex-col lg:flex-row lg:mx-0 lg:px-4 gap-x-8 justify-between">
          <div
            className="lg:w-1/2 md:p-10 p-10 flex flex-col justify-center "
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <h2 className="text-2xl font-semibold text-center mb-6">
              Technologies
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 gap-y-8 ">
              {/* Image 1 */}
              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/java.svg"
                    alt="Java"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">Java</p>
              </div>

              {/* Image 2 */}
              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/dotnet.1024x1024 (1).png"
                    alt="DotNet"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">DotNet</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/c.svg"
                    alt="DotNet"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">C++</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/spring.svg"
                    alt="SpringBoot"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">SpringBoot</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/hibernate-icon.svg"
                    alt="Hibernate"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">Hibernate</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/sql.svg"
                    alt="SQL"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">SQL</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/angular.svg"
                    alt="Angular"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">Angular</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/react.svg"
                    alt="React"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">React</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/KnimeImg.png"
                    alt="KNIME"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">KNIME</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/python.svg"
                    alt="Python"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">Python</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/data-science.svg"
                    alt="Data Science"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">Data Science</p>
              </div>
              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/artificial-intelligence.png"
                    alt="Data Science"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">GenAI</p>
              </div>
              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/Machine Learning.png"
                    alt="Data Science"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">Machine Learning</p>
              </div>

              <div className="flex flex-col items-center relative group"
                onClick={(e) => e.stopPropagation()}>
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110"
                  onClick={() => setShowTooltip(!showTooltip)}>
                  <img
                    src="/assets/images/brackets.png"
                    alt="Coding"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain p-2"
                  />
                </div>
                <p className="mt-4 text-center text-xl font-serif">Your Choice Our Expertise</p>
                {/* Tooltip */}

                <div
                  className={`absolute bottom-full mb-2 flex flex-col items-center ${showTooltip ? 'flex' : 'hidden'
                    } group-hover:flex sm:hidden`} // Shows on hover for large screens and toggle visibility for small screens
                >
                  <div className="bg-gray-700 text-white text-sm sm:text-base rounded py-1 px-2"
                    style={{ backgroundColor: '#01a8ef' }}>

                    Offers training in diverse technologies like EJB, Knockout.js, QA, SharePoint, Oracle, Flutter, and more.
                  </div>
                  <div className="w-3 h-3 bg-gray-700 rotate-45 transform"></div>
                </div>
              </div>

            </div>
           
          </div>
          <div
            className="lg:w-1/2  "
            data-aos="fade-left"
            data-aos-duration="1000"
            id="web-development"
          >
            <div>
              <img
                src="/assets/images/internp.png"
                className="object-contain w-full h-full"
              />
            </div>
             {/* Blinking button at the bottom */}
             <div className="mt-12 flex justify-center">
              <button
                className="btn-style-1 text-xl mt-2 p-4 sm:p-4   animate-blink hover:animate-none "
                onClick={() => downloadPDF(pdfPaths)}
              >
                Download Brochure
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center py-14   fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="text-3xl sm:text-5xl xl:text-3xl font-bold ">
                Features{" "}
              </p>
            </div>
          </h1>
        </div>

        <div
          ref={tabRef}
          className="container  mx-auto py-10 px-4 hidden  lg:block">
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div md:w-1/2"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => setTabIndex(index)} // Update tabIndex on click
                  className={`${tabIndex === index
                      ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                      : "text-gray-500 py-2 px-4"
                    } transition duration-200 ease-in-out transform hover:text-blue-500 hover:scale-105`}
                  style={{
                    cursor: "pointer",
                    color: tabIndex === index ? "blue" : "inherit", // Inline style for color
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl  mb-6 ${tabIndex === index ? "text-blue-500" : "text-gray-500"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>

            <div className="tab-content md:w-1/2 p-4" id="slide-sec">
              {data[tabIndex] && (
                <motion.div
                  key={tabIndex} // Re-run animation on tabIndex change
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={fadeVariants}
                  className="relative w-full"
                >
                  <div className="relative w-full md:w-[20rem] h-64 mb-6 md:mb-0">
                    <div className="w-full h-full bg-gray-200 overflow-hidden rounded-lg">
                      <motion.img
                        src={data[tabIndex].image}
                        alt={`${data[tabIndex].label} image`}
                        className="object-cover w-full h-full rounded-lg"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.5 } }}
                      />
                    </div>

                    <motion.div
                      // className="bg-white shadow-lg p-8 rounded-lg w-full md:w-[calc(100%--3rem)] absolute top-[7rem] left-[12rem] md:h-auto overflow-auto"
                      className="custom-card"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { duration: 0.5 } }}
                    >
                      <p className="text-md md:text-lg">
                        {data[tabIndex].desc}
                      </p>
                    </motion.div>
                  </div>
                </motion.div>
              )}
            </div>
          </Tabs>
        </div>

        <div
          ref={tabRef}
          className="container mx-auto py-10 px-4 md:block lg:hidden">
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
            className="block md:flex"
          >
            <TabList
              className="rounded-none bg-transparent p-0 tablist-div md:w-1/2"
              indicatorProps={{
                className: "bg-transparent shadow-none rounded-none",
              }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {data.map(({ label }, index) => (
                <Tab
                  key={index}
                  onClick={() => handleTabClick(index)}// Update tabIndex on click
                  className={`${tabIndex === index
                      ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                      : "text-gray-500 py-2 px-4"
                    }`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="#slide-sec"
                    onClick={(event) => event.preventDefault()}
                  >
                    <p
                      className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl mb-6 ${tabIndex === index ? "text-blue-500" : "text-gray-500"
                        }`}
                    >
                      <span
                        className="bullet-point"
                        style={{
                          width: "3px", // Thin vertical line
                          height: "30px", // Length of the vertical line
                          backgroundColor: "#01a8ef", // custom color for the line
                          display: "inline-block",
                          marginRight: "1rem",
                          borderRadius: "0", // Keep it a straight line with no rounded corners
                        }}
                      ></span>
                      {label}
                    </p>
                  </a>
                </Tab>
              ))}
            </TabList>

            <div ref={scrollRef} className="tab-content md:w-1/2 p-4" id="slide-sec" style={{ scrollMarginTop: "8.5rem" }}>
              {data[tabIndex] && (
                <div className="relative w-full">
                  {/* Image and Description Section */}
                  <div className="flex flex-col md:flex-col items-center md:items-start">
                    {/* Image Section */}
                    <div className="relative w-full md:w-[20rem] h-64 mb-6 md:mb-0">
                      <div className="w-full h-full bg-gray-200 overflow-hidden rounded-lg">
                        <img
                          src={data[tabIndex].image}
                          alt={`${data[tabIndex].label} image`}
                          className="object-cover w-full h-full rounded-lg"
                        />
                      </div>
                    </div>

                    {/* Description Section */}
                    <div className="bg-white shadow-lg p-4 rounded-lg w-full md:w-[calc(100%-1rem)] md:ml-0 mt-4 md:mt-0">
                      <p className="text-md md:text-lg">
                        {data[tabIndex].desc}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Tabs>
        </div>

        <Clientele />
        <SuccessStoryCarousel />
        <StudentTestimonials />
        <Footer />
        <StickyElements />
      </div>
    </>
  );
};

export default FreshersTraining;
