import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import getHelmetContent from "../../Components/Common/HelmetData";

// import ClientLogoCarousel from '../Components/Home/ClientLogoCarousel';

const VideoSystem = () => {
  useEffect(() => {
    document.title = "Video System - Think Quotient";
    AOS.init();

  }, []);

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    adaptiveHeight: true,
    slidesToScroll: 1,
    prevArrow: <div className="slick-arrow slick-prev ">&#x2190;</div>,
    nextArrow: <div className="slick-arrow slick-next">&#x2192;</div>,
  };

  const clientLogos = [
    "/assets/images/logo-1.png",
    "/assets/images/logo-2.png",
    "/assets/images/logo-3.webp",
    "/assets/images/logo-4.svg",
    "/assets/images/logo-2.png",
    "/assets/images/logo-3.webp",
  ];

  const visionMissionContent = [
    {
      description: "Key Features:",
      list_title: "Key Features:",
      list: [
        "Technological Diversity: The Video System offers a comprehensive library of videos covering a variety of technologies, including Java, .NET, Angular, React, and Python.",
        "Subscription-Based Model: Users can access the first 5 videos of each technology for free. To unlock additional content, users must subscribe and make payments.",
        "Interview Question Repository: The system provides an audio library containing interview questions and answers, helping students prepare for interviews effectively.",
        "Technology Stack: The project was developed using .NET for backend operations, HTML and CSS for the frontend interface, and JavaScript and jQuery for enhanced interactivity.",
        "User Analytics: The system tracks and records user interactions, including video and audio access, providing valuable insights for future content development and system optimization.",
        "User Roles: There are two types of users - students and administrators. Students have access to video and audio content, while administrators have the authority to manage student subscriptions by extending or suspending them.",
      ],
      image: "/assets/images/keyf.jpg",
    },
    {
      description: "Benefits",
      list_title: "Benefits:",
      list: [
        "Educational Value: The Video System provides a valuable resource for students and professionals seeking to expand their knowledge in various technology domains.",
        "Monetization: The subscription-based model generates revenue through paid access to premium content.",
        "Interview Preparation: The inclusion of interview question audio enables students to improve their interview skills and increase their employability.",
        "Data-Driven Insights: User analytics help in understanding user behavior and preferences, facilitating data-driven decisions for content curation and system improvements.",
        "User Management: Administrators have the flexibility to manage student subscriptions, ensuring a seamless user experience.",
      ],
      image: "/assets/images/benefits.jpg",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    arrow: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
    prevArrow: (
      <button type="button" className="slick-arrow slick-prev">
        Left &lt;
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-arrow slick-next">
        Right &gt;
      </button>
    ),
  };
  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();

  return (

    <>
      <Helmet>
        <meta name="title" content={metaTitle} />
        {/* Remaining meta tags */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Script for Facebook */}
        {helmetContent.script}

        {/* Updated Google Tag Manager */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTNBVH9V');
          `}
        </script>

        {/* Google Analytics Scripts */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "UA-143016865-1");
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-BSFPPN5E9D');
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'AW-574047969');
          `}
        </script>

        {/* Google Tag Manager noscript fallback */}
        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
      </Helmet>

      <div>
        {/* Header Section */}
        <Header />

        {/* Banner Section */}
        <div className="flex">
          <div className="videos-banner-sec flex items-center">
            <div className="container text-left text-white mx-auto">
              <div className="md:w-1/2 mx-4 md:mx-0"
                data-aos="fade-up"
                data-aos-duration="1000">
                <h1 className="text-2xl md:text-3xl font-semibold mb-4 ">
                  Video System
                </h1>
                <Link to="/contact?scrollTo=contact-form">
                  <button className="btn-style-2 px-6 py-2 ">
                    Get In Touch With Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>


        <div className="flex flex-col items-center py-14  bg-gray-100 fade-in-down px-6">
          <h1 className="text-lg font-normal container mx-auto text-center" data-aos="fade-up"
            data-aos-duration="1000">
            <div>
              <p className="font-bold text-2xl ">
                The Video System project is a subscription-based service
              </p>
            </div>
            <br /> Designed
            to provide users with access to a wide range of video content related to various
            technologies such as Java, .NET, Angular, React, and Python. This
            system also offers an audio library containing interview questions and
            answers to help students prepare for interviews. Developed using .NET,
            HTML, CSS, JavaScript, and jQuery, the Video System ensures seamless
            access to educational content and tracks user interactions for
            analytics purposes. This case study explores the key features,
            architecture, and benefits of the Video System.
          </h1>
        </div>

        {/* <div className="flex justify-evenly">
                <div className="counter-item text-center">
                    <div className="h-36 flex items-center justify-center ">
                        <img src="/assets/images/growth.svg" className=" w-28" />
                    </div>
                    <p className='font-bold text-2xl w-5/6 mx-auto py-4'>Fueling Growth & Innovation</p>
                </div>

                <div className="counter-item text-center">
                    <div className="h-36 flex items-center justify-center ">
                        <img src="/assets/images/targeting.svg" className=" w-28 " />
                    </div>
                    <p className='font-bold text-2xl w-4/6 mx-auto py-4'>Empowering Business of Every Size to Soar</p>
                </div>
            </div> */}

        {/* Vision & Mission Slider */}
        <div className="container mx-auto m-8 about-vm-section pb-20">
          <Slider {...sliderSettings} className="relative pt-12">
            {visionMissionContent.map((section, sectionIndex) => (
              <div key={sectionIndex} className="">
                <div className="md:flex ">
                  <img
                    src={section.image}
                    alt={`Vision/Mission ${sectionIndex + 1}`}
                    className="pslider-box-img my-20 md:px-0 px-10 object-cover"
                  />
                  <div className="pslider-box flex  flex-col justify-center ">
                    <p className="md:ps-14 ps-4 font-semibold md:w-2/3 text-lg mb-6 p-4">
                      {section.description}
                    </p>
                    {section.list.map((item, index) => (
                      <p
                        key={index}
                        className="md:ms-16 ms-4 ps-6 text-md  w-2/3  list-items my-5"
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};

export default VideoSystem;
