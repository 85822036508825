import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link ,useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AOS from "aos";
import "aos/dist/aos.css";
import ApiPath from "../Common/Apiurl";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from "react-icons/fa";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const Footer = () => {
  const [inputEmail, setInputEmail] = useState("");
  const [formVisible, setFormVisible] = useState(true);
  const [formMessage, setFormMessage] = useState(null);
  const [messageColor, setMessageColor] = useState("text-black mb-7");

  useEffect(() => {
    AOS.init();
  }, []);

  const scrollToContactForm = () => {
    const contactFormSection = document.getElementById("contact-form");
    if (contactFormSection) {
      contactFormSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  
    const location = useLocation();
    const navigate = useNavigate();
  
    const handleClick = () => {
      // Check if the current location is the home page
      console.log(location.pathname);
      if (location.pathname === '/' || location.pathname === '/contact') {
        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      // For other pages, the Link component will handle the navigation
    };


    const handleNavLinkClick = (path) => {
      // Scroll to the top if clicking on the same page, otherwise navigate to the new page
      if(path == "/careers/work-with-us/#history"){
        path = "/careers/work-with-us/";
      }
      console.log("path");
      if (location.pathname === path) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        navigate(path);
      }
    };


  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const apiEndpoint = ApiPath + "/newsletter";
      let request = {
        email: values.email
      }
      try {
        const response = await fetch(apiEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(request),
        });

        if (response.ok) {
          const responseData = await response.json();
          console.log(responseData + " Resp");
          if (responseData.STATUSCode === 200) {
            setMessageColor("text-green-500 mb-6 text-xl");
            setFormMessage("Thank you for subscription");
            setInputEmail("");

            setFormVisible(false);


          } else {
            setFormMessage("Something is wrong");
            setMessageColor("text-red-500");
          }
        } else {
          console.error("Error submitting the form", response.statusText);
        }
      } catch (error) {
        console.error("Error submitting the form", error);
        setFormMessage("Something is wrong");
        setMessageColor("text-red-500");
      }
    },
  });


  return (
    <>
      <div className="top-footer py-10 block md:flex justify-evenly items-center overflow-hidden">
        <div data-aos="fade-right" data-aos-duration="1000"
        >
          <h1 className="md:text-3xl md:text-left text-white font-bold md:w-2/3 text-center">
            Excited to Ignite Technological Renaissance in Your Business Ecosystem?
          </h1>
        </div>
        <div
          className="flex  justify-center mt-6 md:mt-0"
          data-aos="fade-left"
          data-aos-duration="1000"

        >
          {/* <Link to="/contact" onClick={scrollToContactForm} >
            <button className="btn-style-3 px-6 py-2">Enquire Now →</button>
          </Link> */}
          <Link to={location.pathname === '/contact?scrollTo=contact-form' ? '#' : '/contact?scrollTo=contact-form'} onClick={handleClick}>
          
            <button className="btn-style-3 px-6 py-2">Enquire Now →</button>
          </Link>
        </div>
      </div>
      <div className="md:flex ">
        <div className="footer-sections md:flex py-20 md:2xl:space-x-20 md:space-x-10 2xl:px-32 px-4 space-y-10 md:space-y-0">
          {/* First Section */}
          <div className="footer-section md:w-5/12">
            <div className="logo-para">
              <Link to={location.pathname === '/' ? '#' : '/'}>
                <img
                  src="/assets/images/envelop.png"
                  alt="Main Logo"
                  className="h-14 mr-4 mb-10"
                  onClick={handleClick}
                />
              </Link>
              {/* Paragraph */}
              <p className="w-4/5">
                Enhance your technical skills and crack interviews at top IT
                companies in India with ThinkQuotient's programs.
              </p>
            </div>

            <ul className=" flex mt-4">

              <span className="mr-4">
                <a href="https://www.instagram.com/thinkquotient_software/" target="_blank">
                  <FaInstagram style={{ fontSize: '1.8em' }} className="skyblue-image"/>
                </a>
              </span>
              <span className="mr-4">
                <a href="https://www.linkedin.com/company/thinkquotient?originalSubdomain=in" target="_blank">
                  <FaLinkedinIn style={{ fontSize: '1.8em' }} className="skyblue-image"/>
                </a>
              </span>
              <span className="mr-4">
                <a href=" https://www.youtube.com/@thinkquotient1429" target="_blank">
                  <FaYoutube style={{ fontSize: '1.9em' }} className="skyblue-image"/>
                </a>
              </span>
              <span className="mr-4">
              <a href=" https://www.facebook.com/ThinkQuotient" target="_blank">
                <FaFacebookF style={{ fontSize: '1.6em' }} className="skyblue-image"/>
              </a>
              </span>


            </ul>
          </div>

          {/* Second Section */}
          <div className="footer-section w-1/4">
            <div className="section-heading">
              <h1 className="text-xl font-semibold mb-4 md:mb-10">Resources</h1>
            </div>
            <ul className="section-list">
      <li>
        <Link to="/" onClick={() => handleNavLinkClick('/')}>
          Home
        </Link>
      </li>
      <li>
        <Link to="/about" onClick={() => handleNavLinkClick('/about')}>
          About
        </Link>
      </li>
      <li>
        <Link to="/contact" onClick={() => handleNavLinkClick('/contact')}>
          Contact Us
        </Link>
      </li>
      <li>
        <Link to="/careers/work-with-us/#history" onClick={() => handleNavLinkClick('/careers/work-with-us/#history')}>
          History
        </Link>
      </li>

      <li>
        <Link to="/privacy-policy" onClick={() => handleNavLinkClick('/privacy-policy')}>
          Privacy Policy
        </Link>
      </li>
    </ul>

          </div>

          {/* Third Section */}
          <div className="footer-section md:w-1/2">
            <div className="section-heading">
              <h1 className="text-xl font-semibold mb-4 md:mb-10">
                Contact us
              </h1>
            </div>
            <ul className="section-list">
              <li>
                <b>Phone : </b>
                <a href="tel:+917030055128" className="header-contact-hover-2">
                  +91-7030055128
                </a>
              </li>
              <li>
                <b>Email : </b>
                <a
                  href="mailto:support@thinkquotient.com"
                  className="header-contact-hover-2"
                >
                  support@thinkquotient.com
                </a>
              </li>
              <li>
                <b>Address : </b>
                <a
                  href="https://www.google.com/maps/place/ThinkQuotient+Software+Private+Limited/@18.5074619,73.8275037,17z/data=!3m2!4b1!5s0x3bc2bf927fc7542f:0xc81d4bb8327c74e9!4m6!3m5!1s0x3bc2c01b4c092d2d:0x7c010136a378ae2a!8m2!3d18.5074619!4d73.8300786!16s%2Fg%2F11bx1hq4rt?entry=ttu"
                  target="_blank"
                  className="header-contact-hover-2 text-sm"
                >
                  2nd Floor, Nirmitee Elite, 103, Law College Rd, next to Starbucks, Shanti Sheela Society, Apex Colony, Erandwane, Pune, Maharashtra 411004
                </a>
              </li>
            </ul>
          </div>

        </div>

        {/* Second Section (30%) */}
        <div className="footer-sections-2 py-20 2xl:px-24 md:ps-12 ps-6">
          <div className="subscribe-section">
            <h1 className="text-xl font-semibold mb-10">Join Our Newsletter</h1>
            <form onSubmit={formik.handleSubmit}>
              {formVisible && (
                <>
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email Id"
                    className="px-4 py-2 bg-transparent border border-gray-400 mb-4"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  <button
                    type="submit"
                    className="btn-style-4 px-4 py-2 !cursor-pointer"
                  >
                    Sign Up
                  </button>
                  {formik.errors.email && formik.touched.email && (
                    <p className="text-red-500 mb-6">{formik.errors.email}</p>
                  )}
                </>
              )}
            </form>
            <div className="mb-6">
              {formMessage && <p className={messageColor}>{formMessage}</p>}
            </div>
            <p>We Only Send Interesting And Relevant Emails.</p>
          </div>
        </div>
      </div>
      <div className="bottom-footer md:flex text-white  justify-between  px-4 md:px-20 italic py-6 text-center">
        <p>&copy; Copyright 2024 by  

             <Link to={location.pathname === '/' ? '#' : '/'}>
              <span onClick={handleClick}> ThinkQuotient </span>  
              </Link>
              Any assurances made by the Company shall not be construed as Guarantee and past record is no guarantee of future prospects.</p>
        
      </div>
    </>
  );
};

export default Footer;
