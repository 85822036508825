import React, { useEffect, useState } from "react";
import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import TestimonialsandBlogSec from "../../Components/Home/TestimonialsandBlogSec";
import WhyUs from "../../Components/Home/WhyUs";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import getHelmetContent from "../../Components/Common/HelmetData";

const Webinars = () => {
  useEffect(() => {
    document.title = "Webinars - Think Quotient";
    AOS.init();
    setMsg("");
  }, []);

  const [msg, setMsg] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();

    // Extract form data
    const formData = new FormData(event.target);
    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });

    // Log form data to the console
  };
  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();

  const link = "https://us02web.zoom.us/webinar/register/WN_gw5kP4CsQX6SdidGZsvc1w";

  const handleLink = () => {
    if (link) {
      window.open(link, '_blank');
    } else {
      setMsg('No session is available. Kindly check the webinar page after some time.');
    }
  };

  return (

    <>
      <Helmet>
        <meta name="title" content={metaTitle} />
        {/* Remaining meta tags */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Script for Facebook */}
        {helmetContent.script}

        {/* Updated Google Tag Manager */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTNBVH9V');
          `}
        </script>

        {/* Google Analytics Scripts */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "UA-143016865-1");
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-BSFPPN5E9D');
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'AW-574047969');
          `}
        </script>

        {/* Google Tag Manager noscript fallback */}
        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
      </Helmet>

      <div className="overflow-hidden">
        {/* Header Section */}
        <Header />

        {/* Banner Section */}
        <div>
          <div className="webinar-banner-sec flex items-center">
            <div className="container text-left text-white mx-auto">
              <div
                className="md:w-1/2 mx-4 md:mx-0"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h1 className="text-2xl md:text-6xl font-semibold mb-4 ">
                  Webinars
                </h1>
                <p className="text-lg mb-8">
                  ThinkQuotient: Internship Program Session & Technical Seminar
                </p>
                {/* <Link to="/contact">
                <button className="btn-style-2 px-6 py-2 ">Register Now</button>
              </Link> */}
                {/* <a href={link} target="_blank">
                <button className="btn-style-2 px-6 py-2 ">Register Now</button>
              </a> */}
                <button className="btn-style-2 px-6 py-2" onClick={handleLink}>
                  Register Now
                </button>

                <p className="text-base mt-3 font-bold">{msg}</p>
                <p className="text-base mt-3 font-bold"> Date & Time: April 11th, 2024 @12:00 PM (Thursday)</p>

              </div>
            </div>
          </div>
        </div>

        {/* <div className="flex flex-col items-center py-14  bg-gray-100  px-6">
        <h1
          className="text-lg font-normal container mx-auto text-left"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div>
            <p className="font-bold md:text-2xl text-xl">
              Apply Now - Developer Program Employment @ThinkQuotient
            </p>
          </div>
          <br />
          Currently, we are hiring 20 developers in our company and our client
          company to whom we will groom and give work on live projects.
          <br />
          <br />
          In this program, you will work on development backend technologies
          like .NET core,Spring Boot and databases like mysql,MS SQL
          server,Oracle and such as angular,react,Bootstrap. Along with managing
          projects on github. <br />
          <br />
          <span className="text-xl font-semibold">
            Program -{" "}
            <span className="font-normal">
              {" "}
              Developer Program With Employment and Developer Program Without
              Employment
            </span>
          </span>
          <br />
          <span className="text-xl font-semibold">
            Criteria -{" "}
            <span className="font-normal">
              BE/B Tech/M Tech/ME/BSC/MSC/MCA/BCA
            </span>
          </span>
          <br />
          <span className="text-xl font-semibold">
            Category -{" "}
            <span className="font-normal">
              {" "}
              Passout year of 2020 and above / Passout year of 2019 and below
            </span>
          </span>
        </h1>
      </div> */}
        <h1
          className="text-lg font-normal container mx-auto text-left"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div>
            <p className="font-bold  md:text-2xl mt-10 text-4xl">
              Apply Now - Internship Program @ThinkQuotient <br />

            </p>

          </div>
        </h1>
        <div className="w-full ImageDesc"
          data-aos="fade-left"
          data-aos-duration="1000"
          id="web-development"
        >
          <img
            src="/assets/images/intern.png"
            className="object-cover h-full w-full p-10"
          />
        </div>

        <div className="w-full ImagePhone"
          data-aos="fade-left"
          data-aos-duration="1000"
          id="web-development"
        >
          <img
            src="/assets/images/internp.png"
            className="object-cover h-full w-full"
          />
        </div>

        {/* text and image box */}

        {/* First */}
        <div className="m-0 md:flex  service-boxes mb-10">
          <div className="md:w-1/2  md:p-20 p-10 flex flex-col justify-center"
            data-aos="fade-right"
            data-aos-duration="1000">
            {/* <h1 className="text-2xl font-bold">Channel Partner Vision</h1> */}
            {/* <p className="mt-4 w-[80%]">To Build a Franchise network Pan India.</p> */}

            <p className="mt-6 w-[80%] list-items ps-4 ">
              Joining and Offer Letter from ThinkQuotient Software Pvt Ltd.{" "}
            </p>
            <p className="mt-6 w-[80%] list-items ps-4 ">
              {" "}
              Immediate salary applicable from 2nd month onwards Rs 5,000/- (In
              Premium module)
            </p>
            <p className="mt-6 w-[80%] list-items ps-4 ">
              {" "}
              Working & grooming on Live Projects.
            </p>
            <p className="mt-6 w-[80%] list-items ps-4 ">
              {" "}
              End Client company internship as per availability (In Premium
              module){" "}
            </p>
            <p className="mt-6 w-[80%] list-items ps-4 ">
              {" "}
              Will Provide Experience Letter and salary slips. (Subject to program
              module){" "}
            </p>
            <p className="mt-6 w-[80%] list-items ps-4 ">
              {" "}
              Gain valid 1 year experience with ThinkQuotient Software Pvt. Ltd.{" "}
            </p>
            <p className="mt-6 w-[80%] list-items ps-4 ">
              {" "}
              Availability to upskill/revision of the above technologies (In
              Premium module){" "}
            </p>
            <p className="mt-6 w-[80%] list-items ps-4 ">
              {" "}
              If a candidate gets self-placed then also he/she is eligible for the
              Salary if applicable as per the terms in the agreement.{" "}
            </p>
          </div>
          <div className="md:w-1/2"
            data-aos="fade-left"
            data-aos-duration="1000">
            <img
              src="/assets/images/guarantee.jpg"
              className="object-cover h-full w-full"
            />
          </div>
        </div>

        <TestimonialsandBlogSec />

        <ClientLogoCarousel />

        <Footer />
      </div>
    </>
  );
};

export default Webinars;
