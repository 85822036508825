import React, { useEffect } from "react";
import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import TestimonialsandBlogSec from "../../Components/Home/TestimonialsandBlogSec";
import WhyUs from "../../Components/Home/WhyUs";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import getHelmetContent from "../../Components/Common/HelmetData";

const WhitePapers = () => {
  useEffect(() => {
    document.title = "White Papers - Think Quotient";
    AOS.init();

  }, []);

  const downloadPDF = (pdfPath) => {
    const link = document.createElement("a");
    link.href = pdfPath;
    link.download = pdfPath.split("/").pop(); // Extracting the file name
    link.click();
  };

  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();

  return (

    <>
      <Helmet>
        <meta name="title" content={metaTitle} />
        {/* Remaining meta tags */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Script for Facebook */}
        {helmetContent.script}

        {/* Updated Google Tag Manager */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTNBVH9V');
          `}
        </script>

        {/* Google Analytics Scripts */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "UA-143016865-1");
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-BSFPPN5E9D');
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'AW-574047969');
          `}
        </script>

        {/* Google Tag Manager noscript fallback */}
        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
      </Helmet>

      <div className="overflow-hidden">
        {/* Header Section */}
        <Header />

        {/* Banner Section */}
        <div>
          <div className="whitepaper-banner-sec flex items-center">
            <div className="container text-left text-white mx-auto">
              <div className="md:w-1/2 mx-4 md:mx-0"
                data-aos="fade-up"
                data-aos-duration="1000">
                <h1 className="text-2xl md:text-6xl font-semibold mb-4 ">
                  White Papers
                </h1>
                <p className="text-lg mb-8">
                  Unveiling Insights, Empowering Progress
                </p>
                <Link to="/contact?scrollTo=contact-form">
                  <button className="btn-style-2 px-6 py-2 ">
                    Get In Touch With Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center py-14  bg-gray-100 fade-in-down px-6">
          <h1 className="text-lg font-normal container mx-auto text-left"
            data-aos="fade-right"
            data-aos-duration="1000">
            Welcome to our White Papers hub, your destination where complexity
            meets clarity. Dive into an enriching journey of foresight and
            innovation as we unravel the complexities of the ever-evolving tech
            landscape. Here, you'll gain deep insights, strategic perspectives,
            and practical solutions, empowering your progress and keeping you at
            the forefront of innovation. Join us in exploring this treasure trove
            of insights that transcends the ordinary, unlocking limitless
            possibilities for your business and industry.
          </h1>
        </div>

        {/* PDF section */}
        <div className="container mx-auto py-20 m-0 md:flex  service-boxes  md:space-x-11 px-4 md:px-0">
          <div className="md:w-1/2 flex flex-col items-center"
            data-aos="fade-right"
            data-aos-duration="1000">
            <img
              src="/assets/images/knime-case-study-1.jpg"
              className="object-cover  w-full border shadow-md"
              alt="KNIME_Case_Study_1"
            />
            <button
              className="btn-style-1 px-6 py-2 mt-10 md:w-1/2 mb-10 md:mb-0"
              onClick={() => downloadPDF("/assets/images/KNIME_Case_Study_1.pdf")}
            >
              Download
            </button>
          </div>
          <div className="md:w-1/2   flex flex-col items-center"
            data-aos="fade-left"
            data-aos-duration="1000">
            <img
              src="/assets/images/knime-case-study-2.jpg"
              className="object-cover w-full border shadow-md"
              alt="KNIME_Case_Study_2"
            />
            <button
              className="btn-style-1 px-6 py-2 mt-10 md:w-1/2"
              onClick={() => downloadPDF("/assets/images/KNIME_Case_Study_2.pdf")}
            >
              Download
            </button>
          </div>
        </div>

        <TestimonialsandBlogSec />

        <ClientLogoCarousel />

        <Footer />
      </div>
    </>
  );
};

export default WhitePapers;
