import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import getHelmetContent from "../../Components/Common/HelmetData";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import SuccessStoryCarousel from "../../Components/Home/SuccessStoryCarousel";
import StickyElements from "./StickyElements";
import StudentTestimonials from "./StudentTestimonials";
import Clientele from "./Clientele";
import FreshersBannerSection from "./FreshersBannerSection";
import ApiPath from "../../Components/Common/Apiurl";
import CallBackform from "./CallBackform";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation } from 'react-router-dom';


const validationSchema = Yup.object().shape({
  name: Yup.string()
    .transform((value) => value.trim())
    .required("Name is required"),
  email: Yup.string()
    .transform((value) => value.trim())
    .email("Invalid email address")
    .required("Email is required"),
  phone: Yup.string()
    .transform((value) => value.trim())
    .matches(/^\d{10}$/, "Invalid phone number"),
  // .required("Phone is required"),
  message: Yup.string()
    .transform((value) => value.trim()),
  // .required("Message is required"),
  lookingFor: Yup.string()
    .transform((value) => value.trim())
    .required("Category is required"),
});

const CampaignPage = () => {
  const webDevelopmentRef = useRef(null);
  const softwareDevelopmentRef = useRef(null);
  const appDevelopmentRef = useRef(null);

  const pdfPaths = ["/assets/images/Revised Brochure2024.pdf"];

  const downloadPDF = (pdfPaths) => {
    pdfPaths.forEach((pdfPath) => {
      const link = document.createElement("a");
      link.href = pdfPath;
      link.download = pdfPath.split("/").pop();
      link.click();
    });
  };

  const location = useLocation();

  // Extract the query parameter value
  const searchParams = new URLSearchParams(location.search);
  const flag = searchParams.get('flag');
  useEffect(() => {
    document.title = "Campaign - Think Quotient";
    AOS.init();
  }, []);

  useEffect(() => {
    // Get the entire hash string, including the '?'
    const hashString = window.location.hash;

    // Extract the path and query parameters
    const [path, queryString] = hashString.slice(2).split('?'); // Remove the leading '#/' and split at '?'

    // If you need to parse the query parameters, you can use URLSearchParams
    if (queryString) {
      const queryParams = new URLSearchParams(queryString);
      const scrollToSection = queryParams.get('scrollTo');

      // Scroll to the specified section
      if (scrollToSection) {
        const targetSection = document.getElementById(scrollToSection);

        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }

    // Rest of your useEffect logic
    console.log("------------->", flag);
    console.log(location.state);
  }, []);



  //  console.log("------------->",flag);
  //   const [formVisible, setFormVisible] = useState(true);

  //   const knimeParams = new URLSearchParams(location.search);
  //   const isFromKNIME = knimeParams.get('fromKNIME');
  //   console.log(isFromKNIME);

  //   const formik = useFormik({
  //   initialValues: {
  //     queryPageIndex: "CAMPAIGN",
  //     name: "",
  //     email: "",
  //     phone: "",
  //     message: "",
  //     lookingFor: "Internship Program",
  //   },

  //     validationSchema: validationSchema,
  //     onSubmit: async (values) => {

  //       const apiEndpoint =
  //         ApiPath + "/tqenquiry";

  //       try {
  //         const response = await fetch(apiEndpoint, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(values),
  //         });

  //         if (response.ok) {
  //           formik.resetForm();
  //           setFormVisible(false);
  //           setTimeout(() => {
  //             setFormVisible(true);
  //           }, 3000);
  //         }
  //       } catch (error) {
  //         console.error("Error submitting the form", error);
  //       }
  //     },
  //   });


  // const handleFormVisibilityChange = () => {
  //   setFormVisible(!formVisible);
  // };

  const pageTitle = "Campaign - ThinkQuotient";
  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();



  useEffect(() => {

    AOS.init();

  }, []);

  useEffect(() => {
    // Get the entire hash string, including the '?'
    const hashString = window.location.hash;

    // Extract the path and query parameters
    const [path, queryString] = hashString.slice(2).split("?"); // Remove the leading '#/' and split at '?'

    // If you need to parse the query parameters, you can use URLSearchParams
    if (queryString) {
      const queryParams = new URLSearchParams(queryString);
      const scrollToSection = queryParams.get("scrollTo");
      console.log("hello " + scrollToSection);
      // Scroll to the specified section
      if (scrollToSection) {
        const targetSection = document.getElementById(scrollToSection);

        if (targetSection) {
          targetSection.scrollIntoView({ behavior: "smooth" });
        }
      }
    }

    // Rest of your useEffect logic
  }, []);

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [activeTab, setActiveTab] = React.useState("tab1");
  const [tabIndex, setTabIndex] = React.useState(0);
  const data = [
    {
      count: "01",
      label: "Training by Industry Experts with Placement Assistance",
      value: "tab1",
      image: "",
      desc: ` `,
    },

    {
      count: "02",
      label: "Focus on Hands-on Practice",
      value: "tab2",
      image: "",
      desc: ``,
    },
    {
      count: "03",
      label: "Exposure to Live Projects",
      image: "",
      value: "tab3",
      desc: ``,
    },
    {
      count: "04",
      label: "Grooming for interview preparation",
      image: "",
      value: "tab4",
      desc: ``,
    },
    {
      count: "05",
      label: "2000+ Students Succesfully Placed",
      value: "tab5",
      image: "",
      desc: ``,
    },
    {
      count: "06",
      label: "1 year Support for Placement Opportunities",
      value: "tab6",
      image: "",
      desc: ``,
    },
  ];



  return (
    <>
      <Helmet>
        {/* Page Title */}
        <title>{pageTitle}</title>
        <meta name="title" content={metaTitle} />

        {/* Meta Tags */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Social Media Sharing */}
        <meta property="og:title" content={metaTitle || "Campaign - ThinkQuotient"} />
        <meta
          property="og:description"
          content="Discover ThinkQuotient's IT training campaigns. We offer tailored courses to help you excel in web, software, and app development with placement support."
        />
        <meta
          property="og:image"
          content="https://www.thinkquotient.com/assets/images/campaign-banner.png"
        />
        <meta property="og:url" content="https://www.thinkquotient.com/campaign" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTitle || "Campaign - ThinkQuotient"} />
        <meta
          name="twitter:description"
          content="Join ThinkQuotient's IT campaigns and explore training in web, software, and app development. Placement assistance guaranteed!"
        />
        <meta
          name="twitter:image"
          content="https://www.thinkquotient.com/assets/images/campaign-banner.png"
        />

        {/* Robots and Author */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Think Quotient" />

        {/* Google Tag Manager */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTNBVH9V');
        `}
        </script>

        {/* Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "UA-143016865-1");
        `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-BSFPPN5E9D');
        `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'AW-574047969');
        `}
        </script>

        {/* Google Tag Manager noscript */}
        <noscript>{`
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      `}</noscript>
      </Helmet>



      <div className="overflow-hidden">


        <div className="flex flex-col items-center py-14   px-6 campain-banner-sec">

          {/* Logo Section */}
          <a href="https://www.thinkquotient.com" rel="noopener noreferrer">
            <img
              src="/assets/images/tq-logo.png"
              alt="Logo"
              className="absolute top-4 left-4 h-16 w-auto mb-4 "
            />
          </a>
          <h1
            className="text-base sm:text-lg font-normal container mx-auto text-center color-[#ffffff]"
          >
            <div>
              <p className="font-bold text-xl sm:text-2xl mt-6">
                Empowering Knowledge and Opportunities at ThinkQuotient{" "}
              </p>
            </div>
            <br /> At ThinkQuotient Software, we believe in empowering every
            learner. Our training programs are designed to shape your skills and open doors to endless
            IT opportunities. Best place for freshers to start their IT journey
          </h1>
        </div>

        {/* Responsive Div with Grey Background */}
        {/* <div className="bg-gray-200 py-8 px-6 text-center rounded-md shadow-lg">
            <h2 className="text-xl md:text-2xl font-semibold text-gray-800">
              Still looking for an IT job?
            </h2>
            <p className="text-lg md:text-xl text-gray-600 mt-4">
              2000+ freshers placed across 450+ organizations. <br />
              <span className="text-blue-600 font-bold">You can be the next one.</span>
            </p>
          </div> */}
        <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 py-10 px-8 text-center rounded-lg shadow-xl transform transition duration-300 hover:scale-105">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-800 flex items-center justify-center">
            <span className="mr-3">
              <img
                src="/assets/images/job-offer.png"
                alt="Job Icon"
                className="h-8 w-8 inline-block"
              />
            </span>
            Still looking for an IT job?
          </h2>
          <p className="text-lg md:text-xl text-gray-700 mt-6 leading-relaxed">

            <span className="text-blue-600 font-bold"> 2000+ freshers placed in 450+ top organizations.</span>
            <br />
            <span className="block mt-4 text-blue-500 text-lg font-bold ">You can be the next one!</span>
          </p>

        </div>

        <div className="flex flex-col md:flex-row gap-8 px-0 md:px-4 lg:px-16 mb-4" >
          {/* Tabs Section */}
          <div className="flex-[0.5] md:w-2/5">
            <div className="flex flex-col items-center py-14   fade-in-down px-6">
              <h1
                className="text-lg font-normal container mx-auto text-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div>
                  <p className="text-3xl sm:text-5xl xl:text-3xl font-bold ">
                    Features{" "}
                  </p>
                </div>
              </h1>
            </div>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
              className="block"
            >
              <TabList
                className="rounded-none bg-transparent p-0 tablist-div"
                indicatorProps={{
                  className: "bg-transparent shadow-none rounded-none",
                }}
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                {data.map(({ label }, index) => (
                  <Tab
                    key={index}
                    onClick={() => setTabIndex(index)}
                    className={`${tabIndex === index
                      ? "bg-blue-200 text-blue-700 font-bold py-2 px-4 rounded"
                      : "text-gray-500 py-2 px-4"
                      } transition duration-200 ease-in-out transform hover:text-blue-500 hover:scale-105`}
                    style={{
                      cursor: "pointer",
                      color: tabIndex === index ? "blue" : "inherit",
                    }}
                  >
                    <a
                      href="#slide-sec"
                      onClick={(event) => event.preventDefault()}
                    >
                      <p
                        className={`md:ps-14 ps-4 font-semibold md:w-full text-lg md:text-xl mb-6 ${tabIndex === index ? "text-blue-500" : "text-gray-500"
                          }`}
                      >
                        <span
                          className="bullet-point"
                          style={{
                            width: "3px",
                            height: "30px",
                            backgroundColor: "#01a8ef",
                            display: "inline-block",
                            marginRight: "1rem",
                            borderRadius: "0",
                          }}
                        ></span>
                        {label}
                      </p>
                    </a>
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          </div>

          {/* Contact Form Section */}
          <div className="flex-[0.5] md:w-3/5 mt-6 md:mt-36 lg:mt-16">
            <CallBackform />
          </div>
        </div>



        <div className="flex flex-col items-center py-14  fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="font-bold text-2xl ">
                Technology Training Programs with Live Projects{" "}
              </p>
            </div>
          </h1>
        </div>

        {/* text and image box */}

        {/* First */}
        <div className="m-0 flex flex-col lg:flex-row  mx-0 lg:mx-4 px-0 lg:px-4 gap-x-8 justify-between">
          <div
            className="lg:w-1/2 md:p-10 p-10 flex flex-col justify-center bg-gray-100"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <h2 className="text-2xl font-semibold text-center mb-6">
              Technologies
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 gap-y-8 ">
              {/* Image 1 */}
              <div className="flex flex-col items-center">
                <div className="bg-white rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/java.svg"
                    alt="Java"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-2xl">Java</p>
              </div>

              {/* Image 2 */}
              <div className="flex flex-col items-center">
                <div className="bg-white rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/dotnet.1024x1024 (1).png"
                    alt="DotNet"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-2xl">DotNet</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-white rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/c.svg"
                    alt="DotNet"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-2xl">C++</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-white rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/spring.svg"
                    alt="SpringBoot"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-2xl">SpringBoot</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-white rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/hibernate-icon.svg"
                    alt="Hibernate"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-2xl">Hibernate</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-white rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/sql.svg"
                    alt="SQL"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-2xl">SQL</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-white rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/angular.svg"
                    alt="Angular"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-2xl">Angular</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-white rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/react.svg"
                    alt="React"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-2xl">React</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-white rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/KnimeImg.png"
                    alt="KNIME"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-2xl">KNIME</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-white rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/python.svg"
                    alt="Python"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-2xl">Python</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-white rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/data-science.svg"
                    alt="Data Science"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-2xl">Data Science</p>
              </div>
              <div className="flex flex-col items-center">
                <div className="bg-white rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/artificial-intelligence.png"
                    alt="Data Science"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-2xl">GenAI</p>
              </div>
              
            </div>
            
            {/* Blinking button at the bottom */}
            <div className="mt-12 flex justify-center">
              <button
                className="btn-style-1 text-xl mt-2 p-4  animate-blink hover:animate-none "
                onClick={() => downloadPDF(pdfPaths)}
              >
                Download Brochure
              </button>
            </div>
          </div>
          <div
            className="lg:w-1/2 border-2 border-gray-200 "
            data-aos="fade-left"
            data-aos-duration="1000"
            id="web-development"
          >
            <img
              src="/assets/images/internp.png"
              className="object-contain w-full h-full"
            />
          </div>
        </div>





        <Clientele />
        <SuccessStoryCarousel />
        <StudentTestimonials />
        <Footer />
        <StickyElements />
      </div >
    </>
  );
};

export default CampaignPage;
